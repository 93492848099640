import axios from "axios";
import { API_URL } from "../../config/config";
import { GET_ERRORS, GET_SUCCESS } from "./types";

// Accept Declarations
export const acceptDeclarations = () =>
  axios.post(`${API_URL}/api/users/acceptDeclarations`);

// Check Clients Professional Status
export const checkClientsProfessionalStatus = () =>
  axios.post(`${API_URL}/api/users/checkClientsProfessionalStatus`);

// Get Clients Professional Application
export const getProfessionalClientsApplication = () =>
  axios.post(`${API_URL}/api/users/getProfessionalClientsApplication`);

// Submit Clients Professional Application
export const submitClientsProfessionalApplication = profData =>
  axios.post(
    `${API_URL}/api/users/submitClientsProfessionalApplication`,
    profData
  );

// Get Live Accounts
export const getLiveAccounts = (status, currency) =>
  axios.get(`${API_URL}/api/accounts/getLiveAccounts/${status}/${currency}`);

// Get Demo Accounts
export const getDemoAccounts = () =>
  axios.get(API_URL + "/api/accounts/getDemoAccounts");

// Request An Account
export const requestAnAccount = accountData =>
  axios.post(API_URL + "/api/accounts/requestAnAccount", accountData);

// Update Account
export const updateAccount = accountData =>
  axios.post(API_URL + "/api/accounts/updateAccount", accountData);

// Request close account
export const requestCloseAccount = accountId => dispatch =>
  axios
    .post(API_URL + "/api/accounts/requestCloseAccount", { accountId })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Get SafeCharge Session Token
export const getSafeChargeSessionToken =
  (
    transactionType,
    accountNumber,
    accountPlatform,
    paymentMethod,
    currency,
    amount
  ) =>
  dispatch =>
    axios
      .post(API_URL + "/api/accounts/getSafeChargeSessionToken", {
        transactionType,
        accountNumber,
        accountPlatform,
        paymentMethod,
        currency,
        amount,
      })
      .then(res =>
        dispatch({
          type: GET_SUCCESS,
          payload: res.data,
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload:
            err.response !== undefined
              ? err.response.data
              : { server: "Server error." },
        })
      );

// Get ZotaPay Session Token
export const getZotaPaySessionToken =
  (
    transactionType,
    accountNumber,
    accountPlatform,
    paymentMethod,
    currency,
    amount
  ) =>
  dispatch =>
    axios
      .post(API_URL + "/api/accounts/getZotaPaySessionToken", {
        transactionType,
        accountNumber,
        accountPlatform,
        paymentMethod,
        currency,
        amount,
      })
      .then(res =>
        dispatch({
          type: GET_SUCCESS,
          payload: res.data,
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload:
            err.response !== undefined
              ? err.response.data
              : { server: "Server error." },
        })
      );

// Request Wire Payment
export const requestGcenWirePayment = accountData => dispatch =>
  axios
    .post(API_URL + "/api/gcen/requestWirePayment", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Request Wire Withdrawal
export const requestGcenWireWithdrawal = accountData => dispatch =>
  axios
    .post(API_URL + "/api/gcen/requestWireWithdrawal", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Request Wire Transfer
export const requestWireTransfer = accountData => dispatch =>
  axios
    .post(API_URL + "/api/accounts/requestWireTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Request APM Transfer
export const requestApmTransfer = accountData => dispatch =>
  axios
    .post(API_URL + "/api/accounts/requestApmTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Update APM Transfer
export const updateCashierTransfer = accountData => dispatch =>
  axios
    .post(API_URL + "/api/accounts/updateCashierTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Update APM Withdrawal
export const updateCashierWithdrawal = accountData => dispatch =>
  axios
    .post(API_URL + "/api/accounts/updateCashierWithdrawal", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Request Credit Card Transfer
export const requestCreditCardPayment = cardData => dispatch =>
  axios
    .post(API_URL + "/api/gcen/requestCreditCardPayment", cardData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Check Credit Card Payment
export const checkCreditCardPayment = transferData =>
  axios.post(API_URL + "/api/gcen/checkCreditCardPayment", transferData);

// Request withdrawal
export const requestWithdrawal = withdrawalData => dispatch =>
  axios
    .post(API_URL + "/api/accounts/requestWithdrawal", withdrawalData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Request transfer
export const requestTransfer = accountData => dispatch =>
  axios
    .post(API_URL + "/api/accounts/requestTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Get transfer history
export const getTransferHistory = () =>
  axios.get(API_URL + "/api/accounts/getTransferHistory");

// Get trading history
export const getTradingHistory = () =>
  axios.get(API_URL + "/api/accounts/getTradingHistory");

// Save Credit Card
export const addCreditCard = cardData => dispatch =>
  axios
    .post(API_URL + "/api/users/addCreditCard", { cardData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Update Credit Card
export const updateCreditCard = cardData => dispatch =>
  axios
    .post(API_URL + "/api/users/updateCreditCard", { cardData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );

// Get bank details
export const getBankDetails = (currency, status) =>
  axios.get(`${API_URL}/api/accounts/getBankDetails/${currency}/${status}`);

// Get ZotaPay banks
export const getZotaPayBanks = (currency, status) =>
  axios.get(`${API_URL}/api/accounts/getZotaPayBanks/${currency}/${status}`);

// Save bank details
export const addBankDetails = bankData =>
  axios.post(API_URL + "/api/accounts/addBankDetails", { bankData });

// Update bank details
export const updateBankDetails = bankData =>
  axios.post(API_URL + "/api/accounts/updateBankDetails", { bankData });

// Get documents
export const getDocuments = documentType =>
  axios.get(API_URL + "/api/users/getDocuments/" + documentType);

// Save documents
export const saveDocuments = (documentType, data) =>
  axios.post(API_URL + "/api/users/saveDocuments", { documentType, data });

// Send documents for approval
export const sendApprovalDocuments = () =>
  axios.post(API_URL + "/api/gcen/documentsApproval");

// Get SumSub access token
export const getSumSubAccessToken = () =>
  axios.post(API_URL + "/api/users/getSumSubAccessToken");

// Store SumSub ApplicantId
export const storeSumSubApplicantId = sumSubApplicantId =>
  axios.post(API_URL + "/api/users/storeSumSubApplicantId", {
    sumSubApplicantId,
  });

// User Started Uploading KYC Docs
export const setUserStartedUploadingKYCDocs = () =>
  axios.post(API_URL + "/api/users/setUserStartedUploadingKYCDocs");
