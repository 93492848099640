import React, { useState, useEffect } from "react";
import { Button, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

const SidebarUserLinks = props => {
  const [activeItem, setActiveItem] = useState("");
  const [open, setOpen] = useState({
    myAccounts: false,
    funding: false,
    platforms: false,
  });

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [window.location.pathname]);

  const handleMenuItemClicked = destination => {
    setActiveItem(destination);
    if (isMobileOnly) {
      props.hideOnClick(false);
    }
  };

  const WrapperDiv = props => <div>{props.children}</div>;
  const Wrapper = isMobileOnly ? Collapse : WrapperDiv;

  return (
    <>
      <Button
        variant="link"
        className="menuItemTitle"
        onClick={() =>
          setOpen(prevState => {
            return { ...prevState, myAccounts: !open.myAccounts };
          })
        }
        aria-controls="myAccounts"
        aria-expanded={open.myAccounts}
      >
        <span className={!isMobileOnly ? "text-uppercase" : ""}>
          My accounts
        </span>
        {open.myAccounts ? (
          isMobileOnly ? (
            <span className="float-right">&#x25B2;</span>
          ) : (
            ""
          )
        ) : isMobileOnly ? (
          <span className="float-right">&#x25BC;</span>
        ) : (
          ""
        )}
      </Button>
      <Wrapper in={open.myAccounts}>
        <div id="myAccounts" className={isMobileOnly ? "mobileNavLinks" : ""}>
          <Button
            as={Link}
            to="/trading-accounts"
            onClick={() => handleMenuItemClicked("/trading-accounts")}
            className={[
              "menuItem py-2",
              activeItem === "/trading-accounts" ? "active" : null,
            ]}
          >
            {/*<FaChartLine className={classes.iconSize} /> */}
            Trading Accounts
          </Button>
          {/*<Button*/}
          {/*  as={Link}*/}
          {/*  to="/trading-history"*/}
          {/*  onClick={() => handleMenuItemClicked("/trading-history")}*/}
          {/*  className={[*/}
          {/*    "menuItem py-2",*/}
          {/*    activeItem === "/trading-history" ? "active" : null*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  /!*<FaChartLine className={classes.iconSize} /> *!/*/}
          {/*  Trading History*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  as={Link}*/}
          {/*  to="/profile"*/}
          {/*  onClick={() => handleMenuItemClicked("/profile")}*/}
          {/*  className={[*/}
          {/*    "menuItem py-2",*/}
          {/*    activeItem === "/profile" ? "active" : null*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <FaAddressCard className={classes.iconSize} /> Profile*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  as={Link}*/}
          {/*  to="/documents"*/}
          {/*  onClick={() => handleMenuItemClicked("/documents")}*/}
          {/*  className={[*/}
          {/*    "menuItem py-2",*/}
          {/*    activeItem === "/documents" ? "active" : null*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <FaPassport className={classes.iconSize} /> Documents Requests*/}
          {/*</Button>*/}
        </div>
      </Wrapper>

      <Button
        variant="link"
        className="menuItemTitle"
        onClick={() =>
          setOpen(prevState => {
            return { ...prevState, funding: !open.funding };
          })
        }
        aria-controls="funding"
        aria-expanded={open.funding}
      >
        <span className={!isMobileOnly ? "text-uppercase" : ""}>Funding</span>
        {open.funding ? (
          isMobileOnly ? (
            <span className="float-right">&#x25B2;</span>
          ) : (
            ""
          )
        ) : isMobileOnly ? (
          <span className="float-right">&#x25BC;</span>
        ) : (
          ""
        )}
      </Button>
      <Wrapper in={open.funding}>
        <div id="funding" className={isMobileOnly ? "mobileNavLinks" : ""}>
          <Button
            as={Link}
            to="/deposit-funds"
            onClick={() => handleMenuItemClicked("/deposit-funds")}
            // onClick={() => (window.location.href = "/deposit-funds")}
            className={[
              "menuItem py-2",
              activeItem.includes("/deposit-funds") ? "active" : null,
            ]}
          >
            {/*<FaMoneyBillAlt className={classes.iconSize} /> */}
            Deposit Funds
          </Button>
          <Button
            as={Link}
            to="/withdraw-funds"
            onClick={() => handleMenuItemClicked("/withdraw-funds")}
            // onClick={() => (window.location.href = "/withdraw-funds")}
            className={[
              "menuItem py-2",
              activeItem === "/withdraw-funds" ? "active" : null,
            ]}
          >
            {/*<FaMoneyBillAlt className={classes.iconSize} /> */}
            Withdraw Funds
          </Button>
          <Button
            as={Link}
            to="/bank-details"
            onClick={() => handleMenuItemClicked("/bank-details")}
            className={[
              "menuItem py-2",
              activeItem === "/bank-details" ? "active" : null,
            ]}
          >
            {/*<FaMoneyBillAlt className={classes.iconSize} /> */}
            Bank Details
          </Button>
          <Button
            as={Link}
            to="/internal-transfer"
            onClick={() => handleMenuItemClicked("/internal-transfer")}
            className={[
              "menuItem py-2",
              activeItem === "/internal-transfer" ? "active" : null,
            ]}
          >
            {/*<FaMoneyBillAlt className={classes.iconSize} /> */}
            Internal Transfer
          </Button>
          <Button
            as={Link}
            to="/transactions-history"
            onClick={() => handleMenuItemClicked("/transactions-history")}
            className={[
              "menuItem py-2",
              activeItem === "/transactions-history" ? "active" : null,
            ]}
          >
            {/*<FaMoneyBillAlt className={classes.iconSize} /> */}
            Transactions History
          </Button>
          {/*<Button*/}
          {/*  as={Link}*/}
          {/*  to="/bank-details"*/}
          {/*  onClick={() => handleMenuItemClicked("/bank-details")}*/}
          {/*  className={[*/}
          {/*    "menuItem py-2",*/}
          {/*    activeItem === "/bank-details" ? "active" : null*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <FaLandmark className={classes.iconSize} /> Bank details*/}
          {/*</Button>*/}
        </div>
      </Wrapper>

      <Button
        variant="link"
        className="menuItemTitle"
        onClick={() =>
          setOpen(prevState => {
            return { ...prevState, platforms: !open.platforms };
          })
        }
        aria-controls="platforms"
        aria-expanded={open.platforms}
      >
        <span className={!isMobileOnly ? "text-uppercase" : ""}>Platforms</span>
        {open.platforms ? (
          isMobileOnly ? (
            <span className="float-right">&#x25B2;</span>
          ) : (
            ""
          )
        ) : isMobileOnly ? (
          <span className="float-right">&#x25BC;</span>
        ) : (
          ""
        )}
      </Button>
      <Wrapper in={open.platforms}>
        <div id="platforms" className={isMobileOnly ? "mobileNavLinks" : ""}>
          <Button
            as={Link}
            to="/platforms/mt4"
            onClick={() => handleMenuItemClicked("/platforms/mt4")}
            className={[
              "menuItem py-2",
              activeItem === "/platforms/mt4" ? "active" : null,
            ]}
          >
            {/*<FaDownload className={classes.iconSize} /> */}
            MetaTrader 4
          </Button>
          <Button
            as={Link}
            to="/platforms/mt5"
            onClick={() => handleMenuItemClicked("/platforms/mt5")}
            className={[
              "menuItem py-2",
              activeItem === "/platforms/mt5" ? "active" : null,
            ]}
          >
            {/*<FaDownload className={classes.iconSize} /> */}
            MetaTrader 5
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

export default SidebarUserLinks;
