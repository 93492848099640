import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDocuments } from "../../../redux/actions/userActions";
import { Col, Row } from "react-bootstrap";
import classes from "./UploadDocuments.module.css";
import { FaFileUpload } from "react-icons/fa";
import { GoMail } from "react-icons/go";

const ChooseMethod = props => {
  useEffect(() => {
    getDocuments("all")
      .then(res => {
        if (Object.keys(res.data).length) props.changeDocument("choose_type");
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h6 className="mx-4">
            To verify your identity, we will require you to submit a few
            documents. You can either choose to submit these documents to us
            online or can email them to us.
          </h6>
        </Col>
      </Row>

      <Row className="mx-4 p-5 greyBg roundedBox justify-content-around">
        <Col
          className={classes.box}
          onClick={() => props.changeDocument("choose_type")}
        >
          <div className={classes.imgWrapper}>
            <FaFileUpload className={classes.icon} />
          </div>
          <br />
          <div className={classes.title}>
            PROCEED TO
            <br />
            DOCUMENT UPLOAD
          </div>
        </Col>
        <Col className={classes.box} style={{ cursor: "default" }}>
          <div className={classes.imgWrapper}>
            <GoMail className={classes.icon} />
          </div>
          <br />
          <div className={classes.title}>EMAIL DOCUMENTS</div>
          <p className="pt-3">
            Email your Identification and Proof of address documents to:{" "}
            <a href="mailto:clientservices@iquoto.com">
              clientservices@iquoto.com
            </a>
          </p>
        </Col>
      </Row>
    </>
  );
};

ChooseMethod.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ChooseMethod);
