import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import classes from "./ProfessionalClients.module.css";
import { registerUserWeb } from "../../../redux/actions/authActions";

const Step = props => {
  const handleProceed = () => {
    if (props.profData.question03) {
      props.nextStep(1);
    }
  };

  return (
    <>
      <Row className="mx-md-4">
        <Col>
          <h1>Professional Client Status - Size of Portfolio</h1>
        </Col>
      </Row>

      <Row className="mb-5 mx-md-4 p-5 greyBg roundedBox">
        <Col>
          <p>
            <strong>
              The size of your financial instrument portfolio, defined as
              including cash deposits and financial instruments, exceeds EUR
              500,000 (or equivalent in your local currency). Acceptable
              examples of savings and investments: Shares, Money market
              instruments, Units in collective investments e.g. unit trusts,
              UCITS, Cash, ISAs (cash and stocks and shares), CFD and spread
              betting accounts, Derivative contracts (futures, options, swaps
              and forwards) relating to securities, currencies, interest rates,
              financial indices or commodities, SIPPs. (Unacceptable examples of
              savings and investments: Managed pensions, Property, Non-liquid
              assets).
            </strong>
          </p>

          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice01">
                Yes
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice01"
                name="question03"
                value="Yes"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question03 &&
                  props.profData.question03 === "Yes"
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice02">
                No
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice02"
                name="question03"
                value="No"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question03 &&
                  props.profData.question03 === "No"
                }
              />
            </Form.Group>
          </Form.Row>

          <Button
            className={["cardButton px-4", classes.button].join(" ")}
            onClick={() => props.nextStep(-1)}
          >
            BACK
          </Button>
          {props.profData.question03 ? (
            <Button
              className={["cardButton px-4 float-right", classes.button].join(
                " "
              )}
              onClick={handleProceed}
            >
              NEXT
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  registerUserWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUserWeb })(Step);
