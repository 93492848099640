import React from "react";
import { Col, Row, Button, Card, Form } from "react-bootstrap";
import classes from "./Deposit.module.css";
import { getReducedAmount } from "../../../../utils/utils";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const handleUpdateAmount = e => {
    props.updateAmount(e.target.value);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="7">
        <div className="wizardStepsParagraph">
          Please enter the amount that you would like to deposit:
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
          <div className="fundingInputValuesWrapper">
            <Form.Control
              type="number"
              id="amount"
              name="amount"
              className="fundingInputValue h-100"
              placeholder="AMOUNT"
              onChange={e => handleUpdateAmount(e)}
              defaultValue={props.depositAmount || ""}
            />
            <span className="cardFlag cardCurrencyFlag">
              {props.selectedAccount.baseCurrency}
            </span>
          </div>

          <div className="wizardFundAmount">
            <Button
              className="cardButton float-left mt-3 ml-1"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={() => props.nextStep("select_payment_method")}
            >
              BACK
            </Button>
            <Button
              className="cardButton float-right mt-3"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={props.selectAmount}
            >
              NEXT
            </Button>
          </div>
        </div>
      </Col>
      <Col md="5">
        <div className="cardWithLeftBorder">
          <div className="cardBorder red" />
          <Card
            key={props.paymentMethod.id}
            className={[
              "customCardsStyle customFundingMethodCardsStyle",
              classes.PaymentMethodCard,
            ].join(" ")}
          >
            <Card.Body>
              <div style={{ height: "60px" }}>
                <div className="float-left">
                  <div className="cardCaptionTitle">FUNDING METHOD</div>
                  {props.paymentMethod.method}
                </div>
                <div className="float-right">
                  <img
                    src={props.paymentMethod.image}
                    alt={props.paymentMethod.method}
                  />
                </div>
              </div>
              <div style={{ height: "60px" }}>
                <div className="float-left">
                  <div className="cardCaptionTitle">FUNDING TIME</div>
                  {props.paymentMethod.time}
                </div>
                <div className="float-left pl-3">
                  <div className="cardCaptionTitle">FEES</div>
                  {props.paymentMethod.fees}
                </div>
                <div className="float-left pl-1 pt-2">
                  <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                    {props.selectedAccount.baseCurrency}
                  </span>
                </div>
              </div>
              <div>
                <div className="cardCaptionTitle">AMOUNT MINUS FEES</div>
                {getReducedAmount(
                  props.depositAmount,
                  props.paymentMethod.fees
                )}
                <span className="cardFlag cardCurrencyFlag cardCurrencyFlagAmount">
                  {props.selectedAccount.baseCurrency}
                </span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Step;
