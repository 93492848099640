import React, { useState } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../config/config";
import { countryList } from "../../../config/constants";
import {
  addBankDetails,
  updateBankDetails,
} from "../../../redux/actions/userActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { countryListReactSelect } from "../../../utils/utils";
import Select from "react-select";
// Register the plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const BankDetails = props => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [poaFile, setPoaFile] = useState(null);
  const [country, setCountry] = useState(props.bankDetails.bankCountry || "");

  const newBankDetails = props.bankDetails === "";

  const fileID = props.auth.user.id;

  const handleChangeCountry = e => {
    setCountry(e.value);
  };

  const handleAddBankDetails = () => {
    const bankData = {
      userId: props.auth.user.id,
      bankName: document.getElementById("newBankName").value,
      bankAddress: document.getElementById("newBankAddress").value,
      bankCountry: country,
      bankAccountCurrency: document.getElementById("newBankAccountCurrency")
        .value,
      bankPOA: poaFile,
    };
    if (country !== "GB") {
      bankData.bankIBAN = document.getElementById("newBankIBAN").value;
      bankData.bankSwift = document.getElementById("newBankSwift").value;
    }
    if (country === "GB") {
      bankData.bankSortCode = document.getElementById("newBankSortCode").value;
      bankData.bankAccountNumber = document.getElementById(
        "newBankAccountNumber"
      ).value;
    }

    if (
      bankData.bankName === "" ||
      bankData.bankAddress === "" ||
      bankData.bankCountry === "" ||
      bankData.bankAccountCurrency === "" ||
      (country !== "GB" &&
        (bankData.bankIBAN === "" || bankData.bankSwift === "")) ||
      (country === "GB" &&
        (bankData.bankAccountNumber === "" || bankData.bankSortCode === ""))
    ) {
      alert("You must fill all mandatory fields.");
      return false;
    }

    if (!poaFile) {
      alert("You must upload bank statement.");
      return false;
    }

    setBtnDisabled(true);
    addBankDetails(bankData)
      .then(res => {
        // console.log(res.data);
        if (res.data.success) {
          window.location.reload();
        } else {
          setBtnDisabled(false);
          alert("Something went wrong!");
        }
      })
      .catch(err => console.log(err));
  };

  // const handleEditBankDetails = () => {
  //   const bankData = {
  //     bankId: props.bankDetails._id,
  //     bankName: document.getElementById("editBankName").value,
  //     bankAddress: document.getElementById("editBankAddress").value,
  //     bankCountry: country,
  //     bankAccountCurrency: document.getElementById("editBankAccountCurrency")
  //       .value,
  //     bankPOA: poaFile,
  //   };
  //   if (country !== "GB") {
  //     bankData.bankIBAN = document.getElementById("editBankIBAN").value;
  //     bankData.bankSwift = document.getElementById("editBankSwift").value;
  //   }
  //   if (country === "GB") {
  //     bankData.bankSortCode = document.getElementById("editBankSortCode").value;
  //     bankData.bankAccountNumber = document.getElementById(
  //       "editBankAccountNumber"
  //     ).value;
  //   }
  //
  //   if (
  //     bankData.bankName === "" ||
  //     bankData.bankAddress === "" ||
  //     bankData.bankCountry === "" ||
  //     bankData.bankAccountCurrency === "" ||
  //     (country !== "GB" &&
  //       (bankData.bankIBAN === "" || bankData.bankSwift === "")) ||
  //     (country === "GB" &&
  //       (bankData.bankAccountNumber === "" || bankData.bankSortCode === ""))
  //   ) {
  //     alert("You must fill all mandatory fields.");
  //     return false;
  //   }
  //
  //   if (!poaFile) {
  //     alert("You must upload bank statement.");
  //     return false;
  //   }
  //
  //   setBtnDisabled(true);
  //   props.updateBankDetails(bankData);
  // };

  return newBankDetails ? (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditIntlBankDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>New Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Bank Country <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="country"
              required
              options={countryListReactSelect()}
              onChange={e => handleChangeCountry(e)}
              defaultValue={{
                label: "",
                value: "",
              }}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Account currency <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              id="newBankAccountCurrency"
              className="roundedBox"
            >
              <option value="">Select base currency</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Bank Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" id="newBankName" className="roundedBox" />
          </Form.Group>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Bank Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="newBankAddress"
              className="roundedBox"
            />
          </Form.Group>
        </Form.Row>

        {country === "GB" && country !== "" ? (
          <Form.Row>
            <Form.Group as={Col} md="6" xs="12">
              <Form.Label>
                Account Number <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="newBankAccountNumber"
                className="roundedBox"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" xs="12">
              <Form.Label>
                Bank SortCode <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="newBankSortCode"
                className="roundedBox"
              />
            </Form.Group>
          </Form.Row>
        ) : null}

        {country !== "GB" && country !== "" ? (
          <Form.Row>
            <Form.Group as={Col} md="6" xs="12">
              <Form.Label>
                IBAN <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="newBankIBAN"
                className="roundedBox"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" xs="12">
              <Form.Label>
                Bank Swift <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="newBankSwift"
                className="roundedBox"
              />
            </Form.Group>
          </Form.Row>
        ) : null}

        <Form.Row>
          <Form.Group as={Col} md="6" xs="12" />
          <Form.Group as={Col} md="6" xs="12" className="text-right">
            <Form.Label /> <br />
            <span className="text-danger">*</span> Mandatory fields
          </Form.Group>
        </Form.Row>

        <br />

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              Please upload Bank Statement corresponding to the Bank Details
              entered above:
            </Form.Label>

            <FilePond
              onupdatefiles={fileItems => {
                if (fileItems.length > 0) {
                  setPoaFile(prevState => {
                    let files = fileItems.map(fileItem => fileItem.file);
                    if (files.length > 0) {
                      return files[0].name;
                    }
                  });
                }
              }}
              fileRenameFunction={file => {
                let date = new Date();
                let time = date.getTime();
                //prettier-ignore
                return `${fileID}_${time}_${file.basename}${file.extension}`;
              }}
              name={"file"}
              server={`${API_URL}/upload`}
            />
          </Form.Group>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() =>
            props.handleModalClose("showAddEditIntlBankDetailsModal")
          }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="blueButton"
          style={{
            backgroundColor: "var(--color-dark-blue1)",
          }}
          onClick={handleAddBankDetails}
          disabled={btnDisabled}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditIntlBankDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Bank Country <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="country"
              required
              options={countryListReactSelect()}
              onChange={e => handleChangeCountry(e)}
              defaultValue={{
                label: countryList[props.bankDetails.bankCountry] || "",
                value: props.bankDetails.bankCountry || "",
              }}
              isDisabled={props.bankDetails.status === "approved"}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Account currency <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              id="editBankAccountCurrency"
              className="roundedBox"
              defaultValue={props.bankDetails.bankAccountCurrency}
              disabled={props.bankDetails.status === "approved"}
            >
              <option value="">Select base currency</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Bank Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="editBankName"
              className="roundedBox"
              defaultValue={props.bankDetails.bankName}
              disabled={props.bankDetails.status === "approved"}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>
              Bank Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="editBankAddress"
              className="roundedBox"
              defaultValue={props.bankDetails.bankAddress}
              disabled={props.bankDetails.status === "approved"}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          {country === "GB" ? (
            <>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>
                  Account Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="editBankAccountNumber"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankAccountNumber}
                  disabled={props.bankDetails.status === "approved"}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>
                  Bank SortCode <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="editBankSortCode"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankSortCode}
                  disabled={props.bankDetails.status === "approved"}
                />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>
                  IBAN <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="editBankIBAN"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankIBAN}
                  disabled={props.bankDetails.status === "approved"}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>
                  Bank Swift <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="editBankSwift"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankSwift}
                  disabled={props.bankDetails.status === "approved"}
                />
              </Form.Group>
            </>
          )}
        </Form.Row>

        <Form.Row>
          {props.bankDetails.bankPOA ? (
            <Form.Group as={Col} md="6" xs="12">
              <Form.Label>Bank Statement: </Form.Label>{" "}
              <a
                href={`${API_URL}/public/files/${props.bankDetails.bankPOA}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </Form.Group>
          ) : (
            <Form.Group as={Col} md="6" xs="12" />
          )}
          <Form.Group as={Col} md="6" xs="12" className="text-right">
            <span className="text-danger">*</span> Mandatory fields
          </Form.Group>
        </Form.Row>

        <br />

        {/*<Form.Row>*/}
        {/*  {props.bankDetails.bankPOA ? null : (*/}
        {/*    <Form.Group as={Col}>*/}
        {/*      <Form.Label>Upload new bank statement</Form.Label>*/}

        {/*      <FilePond*/}
        {/*        onupdatefiles={fileItems => {*/}
        {/*          if (fileItems.length > 0) {*/}
        {/*            setPoaFile(prevState => {*/}
        {/*              let files = fileItems.map(fileItem => fileItem.file);*/}
        {/*              if (files.length > 0) {*/}
        {/*                return files[0].name;*/}
        {/*              }*/}
        {/*            });*/}
        {/*          }*/}
        {/*        }}*/}
        {/*        fileRenameFunction={file => {*/}
        {/*          let date = new Date();*/}
        {/*          let time = date.getTime();*/}
        {/*          //prettier-ignore*/}
        {/*          return `${fileID}_${time}_${file.basename}${file.extension}`;*/}
        {/*        }}*/}
        {/*        name={"file"}*/}
        {/*        server={`${API_URL}/upload`}*/}
        {/*      />*/}
        {/*    </Form.Group>*/}
        {/*  )}*/}
        {/*</Form.Row>*/}
      </Modal.Body>
      {/*<Modal.Footer className="border-0 justify-content-between">*/}
      {/*  <Button*/}
      {/*    variant="secondary"*/}
      {/*    className="greyButton"*/}
      {/*    onClick={() => props.handleModalClose("showAddEditIntlBankDetailsModal")}*/}
      {/*  >*/}
      {/*    Cancel*/}
      {/*  </Button>*/}

      {/*  {props.bankDetails.status === "approved" ? null : (*/}
      {/*    <Button*/}
      {/*      type="submit"*/}
      {/*      className="blueButton"*/}
      {/*      style={{*/}
      {/*        backgroundColor: "var(--color-dark-blue1)",*/}
      {/*      }}*/}
      {/*      onClick={handleEditBankDetails}*/}
      {/*      disabled={btnDisabled}*/}
      {/*    >*/}
      {/*      {btnDisabled ? (*/}
      {/*        <>*/}
      {/*          <Spinner*/}
      {/*            as="span"*/}
      {/*            animation="grow"*/}
      {/*            size="sm"*/}
      {/*            role="status"*/}
      {/*            aria-hidden="true"*/}
      {/*          />*/}
      {/*          Submit*/}
      {/*        </>*/}
      {/*      ) : (*/}
      {/*        "Submit"*/}
      {/*      )}*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*</Modal.Footer>*/}
    </Modal>
  );
};

BankDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  addBankDetails: PropTypes.func.isRequired,
  updateBankDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { addBankDetails, updateBankDetails })(
  BankDetails
);
