import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import classes from "./Withdraw.module.css";
import { withdrawalMethodData } from "../../../../config/config";
import { isMobileOnly } from "react-device-detect";
import MissingBankDetailsCurrency from "../../../../components/Modals/MissingBankDetails/MissingBankDetailsCurrency";

const Step = props => {
  return (
    <>
      <Row className="mx-1 mx-md-4 greyBg bottomBorders">
        <Col className="py-5">
          <div className="wizardStepsParagraph">
            Please select your withdrawal method:
          </div>

          <div style={!isMobileOnly ? { paddingLeft: "80px" } : {}}>
            {withdrawalMethodData.map(method => (
              <div
                key={method.id}
                className="cardWithLeftBorder m-1"
                style={{ cursor: "pointer" }}
                onClick={() => props.selectWithdrawalMethod(method)}
              >
                <div className="cardBorder red" />
                <Card
                  className={[
                    "customCardsStyle customFundingMethodCardsStyle",
                    classes.PaymentMethodCard,
                  ].join(" ")}
                >
                  <Card.Body>
                    <div style={{ height: "60px" }}>
                      <div className="float-left">
                        <div className="cardCaptionTitle">
                          WITHDRAWAL METHOD
                        </div>
                        {method.method}
                      </div>
                      <div className="float-right">
                        <img src={method.image} alt={method.method} />
                      </div>
                    </div>
                    <div style={{ height: "60px" }}>
                      <div className="float-left">
                        <div className="cardCaptionTitle">WITHDRAWAL TIME</div>
                        {method.time}
                      </div>
                      <div className="float-left pl-3">
                        <div className="cardCaptionTitle">FEES</div>
                        {method.fees}
                      </div>
                    </div>
                    <div className="cardCaptionParagraph">{method.text}</div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </Col>
      </Row>

      <MissingBankDetailsCurrency
        currency={props.selectedAccount.baseCurrency}
      />
    </>
  );
};

export default Step;
