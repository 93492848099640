import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import classes from "./ProfessionalClients.module.css";

const Step = props => {
  const handleProceed = () => {
    const acceptDeclaration =
      document.getElementById("acceptDeclaration").checked;

    if (
      acceptDeclaration ||
      (!acceptDeclaration &&
        window.confirm(
          "I have read and understood that by electing to be a Professional Client I may lose some protection afforded by the FCA rules and I understand the consequences. Please be aware that you will be required to provide some evidence to support the application.  "
        ))
    ) {
      props.nextStep(1);
    }
  };

  return (
    <>
      <Row className="mx-md-4">
        <Col>
          <h1>Professional Client Status - Disclosure</h1>
        </Col>
      </Row>

      <Row className="mb-5 mx-md-4 p-5 greyBg roundedBox">
        <Col>
          <p>
            <strong>
              Please note that being classified as a professional client, you
              will waive some protections afforded to you as a retail client. If
              you qualify as a Professional Client, you should note that where
              we treat you as a Professional Client rather than a Retail Client,
              a number of FCA rules will cease to apply.
              <br />
              In particular:
            </strong>
          </p>

          <p>
            <strong>• Disclosures:</strong> You may not be provided any of the
            additional disclosures required to be provided to Retail Clients
            (for example on costs, commissions, fees and charges and foreign
            exchange conversion rates). In practice, we have no current
            intention to change the additional disclosures currently available
            to you.
          </p>

          <p>
            <strong>• Communications:</strong> As a Professional Client, we may
            consider your trading expertise and may use more sophisticated
            language than when communicating with Retail Clients.
          </p>

          <p>
            <strong>• Appropriateness:</strong> Where we assess whether a
            product or service is appropriate for you, we can assume that you
            have the necessary level of experience and knowledge to understand
            the risks involved in relation to any investment, service, product
            or transaction.
          </p>

          <p>
            <strong>• Order Execution:</strong> Our order execution requirements
            may differ between Professional and Retail Clients. For Retail
            Clients we prioritise overall price and cost whereas other factors
            such as speed and likelihood of execution may be used for
            Professional Clients. In practice, we do not anticipate changing the
            way we execute your orders.
          </p>

          <p>
            <strong>• Prompt Execution:</strong> We do not need to inform you of
            material difficulties we may have with the prompt execution of your
            orders.
          </p>

          <p>
            <strong>• Client Money:</strong> We will continue to segregate your
            funds under the FCA client money rules.
          </p>

          <p>
            <strong>• Financial Ombudsman Scheme:</strong> 'Professional
            Clients' may be entitled to refer complaints to the FOS as
            'consumers'.
          </p>

          <p>
            <strong>• Financial Services Compensation Scheme:</strong>{" "}
            Professional Clients may still be classified as 'eligible claimants'
            entitled to compensation under the Financial Services Compensation
            Scheme in the event that an authorised firm is unable to fulfil its
            financial obligations.
          </p>

          <p>
            <strong>
              • Regulations restricting leverage and other measure such as
              standardised Risk Warnings and other future measures intended as a
              way to protect Retail Clients will not be applicable to
              Professional Clients.
            </strong>
          </p>

          <p className="pt-3">
            <strong>Declaration</strong>
          </p>

          <Form.Row>
            <Form.Group as={Col}>
              <label htmlFor="acceptDeclaration">
                I have read and understood that by electing to be a Professional
                Client I may lose some protection afforded by the FCA rules and
                I understand the consequences. Please be aware that you will be
                required to provide some evidence to support the application.
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="acceptDeclaration"
              />
            </Form.Group>
          </Form.Row>

          <Button
            className={["cardButton px-4 float-right", classes.button].join(
              " "
            )}
            onClick={handleProceed}
          >
            NEXT
          </Button>
        </Col>
      </Row>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
