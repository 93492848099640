import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Card, Spinner } from "react-bootstrap";
import { getBankDetails } from "../../../../redux/actions/userActions";
import classes from "./Withdraw.module.css";
import moment from "moment";
import { getReducedAmount } from "../../../../utils/utils";

const Step = props => {
  const [state, setState] = useState({
    loading: true,
  });
  const [bankDetails, setBankDetails] = useState([]);

  useEffect(() => {
    getBankDetails(props.selectedAccount.baseCurrency, "approved")
      .then(res => {
        setBankDetails(res.data);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.log(err));
  }, [props.selectedAccount.baseCurrency]);

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="7">
        <div className="wizardStepsParagraph pl-1">
          Please select a bank account to which you would like to withdraw:
        </div>

        {state.loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : null}

        {bankDetails.bankDetails && bankDetails.bankDetails.length > 0
          ? bankDetails.bankDetails.map(bankDetails => (
              <div
                key={bankDetails._id}
                className="cardWithLeftBorder m-1"
                style={{ cursor: "pointer" }}
                onClick={() => props.chooseBank(bankDetails)}
              >
                <div className="cardBorder green" />
                <Card
                  className={[
                    "customCardsStyle customFundingMethodCardsStyle",
                    classes.BankDetailsCardPreview,
                  ].join(" ")}
                >
                  <Card.Body className="pl-3">
                    {bankDetails.bankCountry === "GB" ? (
                      <div style={{ height: "60px" }}>
                        <div className="float-left" style={{ width: "135px" }}>
                          <div className="cardCaptionTitle fontWeightBold500">
                            ACCOUNT NUMBER
                          </div>
                          <span style={{ fontSize: "0.9375rem" }}>
                            {bankDetails.bankAccountNumber}
                          </span>
                        </div>
                        <div className="float-left pl-2">
                          <div className="cardCaptionTitle fontWeightBold500">
                            SORT CODE
                          </div>
                          <span style={{ fontSize: "0.9375rem" }}>
                            {bankDetails.bankSortCode}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div style={{ height: "60px" }}>
                        <div className="float-left" style={{ width: "135px" }}>
                          <div className="cardCaptionTitle fontWeightBold500">
                            IBAN
                          </div>
                          <span
                            style={{
                              display: "block",
                              marginTop: "4px",
                              fontSize: "0.75rem",
                            }}
                          >
                            {bankDetails.bankIBAN}
                          </span>
                        </div>
                        <div className="float-left pl-2">
                          <div className="cardCaptionTitle fontWeightBold500">
                            SWIFT/BIC
                          </div>
                          <span style={{ fontSize: "0.9375rem" }}>
                            {bankDetails.bankSwift}
                          </span>
                        </div>
                      </div>
                    )}

                    <div>
                      <div className="float-left" style={{ width: "135px" }}>
                        <div className="cardCaptionTitle fontWeightBold500">
                          DATE ADDED
                        </div>
                        <span style={{ fontSize: "0.9375rem" }}>
                          {moment(bankDetails.timestamp * 1000).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </div>
                      <div
                        className="float-left pl-2"
                        style={{ maxWidth: "145px" }}
                      >
                        <div className="cardCaptionTitle fontWeightBold500">
                          BANK NAME
                        </div>
                        <span
                          style={{ display: "block", fontSize: "0.9375rem" }}
                        >
                          {bankDetails.bankName}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))
          : null}
      </Col>
      <Col md="5">
        <div className="cardWithLeftBorder">
          <div className="cardBorder red" />
          <Card
            key={props.paymentMethod.id}
            className={[
              "customCardsStyle customFundingMethodCardsStyle",
              classes.PaymentMethodCard,
            ].join(" ")}
          >
            <Card.Body>
              <div style={{ height: "60px" }}>
                <div className="float-left">
                  <div className="cardCaptionTitle">FUNDING METHOD</div>
                  {props.paymentMethod.method}
                </div>
                <div className="float-right">
                  <img
                    src={props.paymentMethod.image}
                    alt={props.paymentMethod.method}
                  />
                </div>
              </div>
              <div style={{ height: "60px" }}>
                <div className="float-left">
                  <div className="cardCaptionTitle">FUNDING TIME</div>
                  {props.paymentMethod.time}
                </div>
                <div className="float-left pl-3">
                  <div className="cardCaptionTitle">FEES</div>
                  {props.paymentMethod.fees}
                </div>
                <div className="float-left pl-1 pt-2">
                  <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                    {props.selectedAccount.baseCurrency}
                  </span>
                </div>
              </div>
              <div>
                <div className="cardCaptionTitle">AMOUNT MINUS FEES</div>
                {getReducedAmount(
                  props.withdrawAmount,
                  props.paymentMethod.fees
                )}
                <span className="cardFlag cardCurrencyFlag cardCurrencyFlagAmount">
                  {props.selectedAccount.baseCurrency}
                </span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
