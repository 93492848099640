import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginUser } from "../../../redux/actions/authActions";
import { encryptData } from "../../../utils/utils";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Card,
} from "react-bootstrap";
import classes from "./Login.module.css";
import { FiAlertTriangle } from "react-icons/fi";
import logo from "../../../assets/images/iQuoto-Logo-Blue.svg";
import { isMobileOnly } from "react-device-detect";

// SOCIAL LOGIN DEPENDENCIES
// import io from "socket.io-client";
// import OAuth from "../SocialLogin/OAuth";
// import { API_URL } from "../../../config/config";
// const socket = io(API_URL);
// const providers = ["twitter", "google", "facebook"];
// SOCIAL LOGIN DEPENDENCIES END

class Login extends Component {
  state = {
    email: "",
    password: "",
    // socialId: "",
    errors: {},
    disableLoginBtn: false,
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated && this.props.auth.user.registered) {
      if (
        this.props.auth.user.status === "approved" ||
        this.props.auth.user.gcenApiKYCPass
      ) {
        window.location.href = "/trading-accounts";
      } else {
        window.location.href = "/upload-documents";
        // window.location.href = "/documents";
      }
    }
  }

  static getDerivedStateFromProps(newProps) {
    if (newProps.auth.isAuthenticated && newProps.auth.user.registered) {
      if (
        newProps.auth.user.status === "approved" ||
        newProps.auth.user.gcenApiKYCPass
      ) {
        window.location.href = "/trading-accounts";
      } else {
        window.location.href = "/upload-documents";
        // window.location.href = "/documents";
      }
    }

    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: newProps.errors,
        disableLoginBtn: false,
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ errors: {} });

    if (form.checkValidity()) {
      this.setState({ disableLoginBtn: true });

      const userData = {
        email: this.state.email,
        password: encryptData(this.state.password),
        // socialId: this.state.socialId
      };

      this.props.loginUser(userData);
    }
  };

  render() {
    const { errors, disableLoginBtn } = this.state;

    return !this.props.auth.isAuthenticated ? (
      <>
        <div
          className="h-100"
          style={
            !isMobileOnly
              ? { backgroundColor: "var(--color-light-gray5)" }
              : null
          }
        >
          <Container fluid>
            <Row>
              <Col
                style={{
                  width: "98%",
                  maxWidth: "1200px",
                  margin: "10px auto 20px",
                  padding: "20px",
                  backgroundColor: "white",
                  fontSize: "0.8rem",
                }}
              >
                <p className="mb-2">Dear Client,</p>
                <p className="mb-2">
                  I wish to inform you that iQuoto Ltd (iQuoto) will shortly
                  cease its trading actives and exit the financial services
                  sector.
                </p>
                <p className="mb-2">
                  As you will be aware, iQuoto operates as an Appointed
                  Representative of Kapwealth Limited (Kapwealth).
                </p>
                <p className="mb-2">
                  It has come to our attention that we have inadvertently acted
                  outside of the regulatory permissions of Kapwealth. While
                  inadvertent, nonetheless this could put you the client at
                  risk.
                </p>
                <p className="mb-2">
                  This voluntary decision to exit the financial services sector
                  has been made and disclosed to the FCA to ensure we act in
                  your best interest and to ensure you are receiving the
                  relevant protections expected and required.
                </p>
                <p className="mb-2">
                  We request that you close any open trading positions prior to
                  the 5th July. Any positions outstanding by 3pm on 5th July,
                  will be automatically closed.
                </p>
                <p className="mb-2">
                  Once approved by the FCA we will then aim to shortly return
                  all funds to the account they were deposited from and close
                  your account.
                </p>
                <p className="mb-2">
                  Further details of voluntary requirements (VREQ) applied for
                  by Kapwealth to formalise steps to regularise this breach of
                  permissions can be found on the FCA register:{" "}
                  <a
                    href="https://register.fca.org.uk/s/firm?id=001b000000NMj3tAAD"
                    target="_blank"
                  >
                    https://register.fca.org.uk/s/firm?id=001b000000NMj3tAAD
                  </a>
                  .
                </p>
                <p className="mb-2">
                  This has been a difficult decision and one not taken lightly.
                  We sincerely apologise for any inconveniences this may cause
                  you.
                </p>
                <p className="mb-2">
                  Lastly, we would really like to thank you for your custom and
                  wish you success for the future.
                </p>
                <p className="mb-2">Kind regards</p>
                <p className="mb-0">iQuoto Ltd</p>
              </Col>
            </Row>
          </Container>

          <Card className={[classes.card, "px-md-5"].join(" ")}>
            <Card.Body>
              <object
                data={logo}
                width="135"
                height="51"
                className={classes.loginLogo}
                onClick={() =>
                  (window.location.href = "https://iquoto.global/")
                }
                aria-label="iQuoto.com"
              />

              <Form onSubmit={e => this.handleSubmit(e)}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className={classes.label}>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      required
                      onChange={e => this.handleChange(e, "")}
                    />
                    <Form.Text style={{ color: "red" }}>
                      {errors.email}
                    </Form.Text>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className={classes.label}>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      required
                      onChange={e => this.handleChange(e, "")}
                    />
                    <Form.Text style={{ color: "red" }}>
                      {errors.password}
                      {errors.passwordincorrect}
                    </Form.Text>

                    <Button
                      variant="link"
                      as={Link}
                      to="/forgot-password"
                      className={classes.forgotPassword}
                    >
                      Forgot password?
                    </Button>
                  </Form.Group>
                </Form.Row>

                <Form.Text className="text-danger text-left pb-4">
                  {errors.server || errors.emailorpassword ? (
                    <FiAlertTriangle className={classes.warningIcon} />
                  ) : null}
                  {errors.server}
                  {errors.emailorpassword}
                </Form.Text>

                <Button
                  type="submit"
                  className="blueButton mt-0 mb-4 float-left"
                  onClick={this.handleSubmit}
                  disabled={disableLoginBtn}
                >
                  {disableLoginBtn ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SIGN IN
                    </>
                  ) : (
                    "SIGN IN"
                  )}
                </Button>
              </Form>

              <br />

              {/*<div*/}
              {/*  className={[*/}
              {/*    "w-100 d-flex justify-content-center pt-2",*/}
              {/*    classes.smallText,*/}
              {/*  ].join(" ")}*/}
              {/*>*/}
              {/*  Not registered yet?*/}
              {/*  <Button*/}
              {/*    variant="link"*/}
              {/*    className="orangeLink ml-1"*/}
              {/*    onClick={() => (window.location.href = "/register")}*/}
              {/*  >*/}
              {/*    Open a Live account.*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </Card.Body>
          </Card>
        </div>
      </>
    ) : (
      <p className="p-5">Logging in...</p>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
