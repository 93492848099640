import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../../config/config";
import { Button, Col, Form, Row } from "react-bootstrap";
import classes from "../UploadDocuments.module.css";
import {
  getDocuments,
  saveDocuments,
} from "../../../../redux/actions/userActions";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import governmentLetter from "../../../../assets/images/documents/governmentletter.png";
import { NotificationManager } from "react-notifications";

// Register the plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const GovernmentLetter = props => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const fileID = props.auth.user.gcenApiClientId
    ? props.auth.user.gcenApiClientId
    : props.auth.user.id;

  useEffect(() => {
    getDocuments("government_letter")
      .then(res => {
        setUploadedFile(res.data.government_letter);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      window.location.reload();
    }

    if (Object.keys(props.errors).length > 0) {
      setButtonsDisabled(false);
    }
  }, [props]);

  const handleSaveDocuments = () => {
    saveDocuments("government_letter", uploadedFile)
      .then(res => {
        if (res.data.success) window.location.reload();
        else NotificationManager.error("Something went wrong!", "", 5000);
      })
      .catch(err => console.log(err));
  };

  return (
    <Row className="mx-4 pt-5 pb-3 greyBg roundedBox">
      <Col xs="12" className={[classes.content, "mx-auto"].join(" ")}>
        <p className={classes.text2}>
          Please upload a valid copy of your proof of address:
        </p>

        <div className={[classes.whiteBox, "roundedBox"].join(" ")}>
          <img
            src={governmentLetter}
            className="mx-auto d-block"
            alt="government_letter"
          />
          <div className={[classes.title, "mt-3"].join(" ")}>
            GOVERNMENT LETTER
          </div>
          <p className={[classes.text, "pt-3"].join(" ")}>
            Government letter must be valid and show all corners
          </p>

          {uploadedFile &&
          uploadedFile.uploaded &&
          uploadedFile.documentFront ? (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Preview</Form.Label>
                <img
                  src={`${API_URL}/public/files/${uploadedFile.documentFront}`}
                  className={classes.imgPreview}
                  alt=""
                />
              </Form.Group>
            </Form.Row>
          ) : (
            <FilePond
              onupdatefiles={fileItems => {
                if (fileItems.length > 0) {
                  let files = fileItems.map(fileItem => fileItem.file);
                  if (files.length > 0) {
                    setUploadedFile({
                      documentFront: files[0].name,
                    });
                  }
                }
              }}
              fileRenameFunction={file => {
                let date = new Date();
                let time = date.getTime();
                //prettier-ignore
                return `${fileID}_${time}_${file.basename}${file.extension}`;
              }}
              name={"file"}
              server={`${API_URL}/upload`}
            />
          )}
        </div>
      </Col>
      <Col xs="12" className="mt-5 d-flex justify-content-around">
        <Button
          className={[classes.btn, classes.btnBack, "roundedBox"].join(" ")}
          onClick={() => props.changeDocument("proof_of_address")}
        >
          BACK
        </Button>
        {uploadedFile &&
        uploadedFile.uploaded &&
        uploadedFile.documentFront ? null : (
          <Button
            className={[classes.btn, classes.btnSubmit, "roundedBox"].join(" ")}
            onClick={() => handleSaveDocuments()}
            disabled={buttonsDisabled}
          >
            SUBMIT
          </Button>
        )}
      </Col>
    </Row>
  );
};

GovernmentLetter.propTypes = {
  auth: PropTypes.object.isRequired,
  saveDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { saveDocuments })(GovernmentLetter);
