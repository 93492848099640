import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  requestAnAccount,
  updateAccount,
} from "../../../redux/actions/userActions";

const AccountDetails = props => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [requestDemoAccount, setRequestDemoAccount] = useState(false);

  const newAccount = props.account === "";

  const handleRequestAnAccount = () => {
    let requestType = document.getElementById("newRequestType").value;

    const accData = {
      userId: props.auth.user.id,
      requestType,
      // nickname: document.getElementById("newNickname").value,
      platform: document.getElementById("newPlatform").value,
      accType: document.getElementById("newAccType").value,
      baseCurrency: document.getElementById("newBaseCurrency").value,
      leverage: document.getElementById("newLeverage").value,
    };

    if (
      accData.platform === "" ||
      accData.accType === "" ||
      accData.baseCurrency === "" ||
      accData.leverage === "" ||
      (requestDemoAccount && accData.balance === "")
    ) {
      return false;
    }

    setBtnDisabled(true);
    requestAnAccount(accData)
      .then(res => {
        if (res.data.success) {
          props.createNotification("accountRequested");
        } else props.createNotification("error", res.data.error);

        handleAfterRequest();
      })
      .catch(err => console.log(err));
  };

  const handleEditAccountDetails = () => {
    const accData = {
      accountId: props.account._id,
      // password: document.getElementById("editPassword").value,
      leverage: document.getElementById("editLeverage").value,
    };

    if (
      // accData.password.toString() === props.account.password.toString() &&
      accData.leverage.toString() === props.account.leverage.toString()
    ) {
      return false;
    }

    setBtnDisabled(true);
    updateAccount(accData)
      .then(res => {
        if (res.data.success) {
          props.createNotification("leverageChangeRequested");
        } else props.createNotification("error", res.data.error);

        handleAfterRequest();
      })
      .catch(err => console.log(err));
  };

  const handleRequestDemoAccount = () => {
    setRequestDemoAccount(
      document.getElementById("newRequestType").value === "demo"
    );
  };

  const handleAfterRequest = () => {
    setBtnDisabled(false);
    props.handleModalClose("showAddEditAccountDetailsModal");
    props.triggerGetAccounts();
  };

  // const handleRequestCloseAccount = accountId => {
  //   if (window.confirm("Do you want to request to close this account?")) {
  //     props.requestCloseAccount(accountId);
  //   }
  // };

  return newAccount ? (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditAccountDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>New account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newRequestType">
          <Form.Label>Request account</Form.Label>
          <Form.Control
            as="select"
            className="roundedBox"
            onChange={handleRequestDemoAccount}
            required
          >
            <option value="live">Live</option>
            <option value="demo">Demo</option>
          </Form.Control>
        </Form.Group>

        {requestDemoAccount ? (
          <Form.Group controlId="newBalance">
            <Form.Label>Balance</Form.Label>
            <Form.Control as="select" className="roundedBox" required>
              <option value="">Select balance</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="5000">5000</option>
              <option value="10000">10000</option>
              <option value="25000">25000</option>
              <option value="50000">50000</option>
            </Form.Control>
          </Form.Group>
        ) : null}

        {/*<Form.Group controlId="newNickname">*/}
        {/*  <Form.Label>Nickname (optional)</Form.Label>*/}
        {/*  <Form.Control type="text" />*/}
        {/*</Form.Group>*/}

        <Form.Group controlId="newPlatform">
          <Form.Label>Platform</Form.Label>
          <Form.Control as="select" className="roundedBox" required>
            <option value="">Select platform</option>
            <option value="MT4">MT4</option>
            <option value="MT5">MT5</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newAccType">
          <Form.Label>Account Type</Form.Label>
          <Form.Control as="select" className="roundedBox" required>
            <option value="">Select account type</option>
            <option value="Standard">Standard</option>
            <option value="ECN">ECN</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newBaseCurrency">
          <Form.Label>Base currency</Form.Label>
          <Form.Control as="select" className="roundedBox" required>
            <option value="">Select base currency</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="USD">USD</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newLeverage">
          <Form.Label>Leverage</Form.Label>
          <Form.Control as="select" className="roundedBox" required>
            <option value="30">30:1</option>
            {props.auth.user.clientStatus &&
            props.auth.user.clientStatus === "professional" ? (
              <>
                <option value="50">50:1</option>
                <option value="100">100:1</option>
                <option value="200">200:1</option>
                <option value="300">300:1</option>
                <option value="400">400:1</option>
                <option value="500">500:1</option>
              </>
            ) : null}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() =>
            props.handleModalClose("showAddEditAccountDetailsModal")
          }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="blueButton"
          style={{
            fontWeight: "normal",
          }}
          disabled={btnDisabled}
          onClick={handleRequestAnAccount}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditAccountDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Account Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/*<Form.Group controlId="editNickname">*/}
        {/*  <Form.Label>Nickname (optional)</Form.Label>*/}
        {/*  <Form.Control type="text" defaultValue={props.account.nickname} />*/}
        {/*</Form.Group>*/}

        <Form.Group>
          <Form.Label>Platform</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.platform}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Account Number</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.number}
            disabled
          />
        </Form.Group>

        <Form.Group controlId="editPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="text" defaultValue={props.account.password} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Account Type</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.type}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Base currency</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.baseCurrency}
            disabled
          />
        </Form.Group>

        <Form.Group controlId="editLeverage">
          <Form.Label>Leverage</Form.Label>
          <Form.Control
            as="select"
            className="roundedBox"
            defaultValue={props.account.leverage}
            required
          >
            <option value="30">30:1</option>
            {props.auth.user.clientStatus &&
            props.auth.user.clientStatus === "professional" &&
            props.account.clientStatus === "professional" ? (
              <>
                <option value="50">50:1</option>
                <option value="100">100:1</option>
                <option value="200">200:1</option>
                <option value="300">300:1</option>
                <option value="400">400:1</option>
                <option value="500">500:1</option>
              </>
            ) : null}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() =>
            props.handleModalClose("showAddEditAccountDetailsModal")
          }
        >
          Cancel
        </Button>
        {/*<Button*/}
        {/*  type="button"*/}
        {/*  variant="danger"*/}
        {/*  onClick={() => handleRequestCloseAccount(props.account._id)}*/}
        {/*>*/}
        {/*  CLOSE*/}
        {/*</Button>*/}
        <Button
          type="submit"
          className="blueButton"
          style={{
            fontWeight: "normal",
          }}
          onClick={handleEditAccountDetails}
          disabled={btnDisabled}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AccountDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AccountDetails);
