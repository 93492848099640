import React from "react";
import classes from "./Layout.module.css";
import Navbar from "../../components/Navigation/Navbar";
import SidebarUser from "../../components/Navigation/SidebarUser";
import SidebarRegister from "../../components/Navigation/SidebarRegister";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import { Container, Row, Col } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { NotificationContainer } from "react-notifications";
import AcceptDeclarations from "../../components/Modals/AcceptDeclarations/AcceptDeclarations";
// import SidebarLogin from "../../components/Navigation/SidebarLogin";
// import FooterHomepage from "../../components/Footer/FooterHomepage";

const Layout = props => {
  const onLogoutClick = e => {
    e.preventDefault();
    props.logoutUser();
  };

  const HeaderWithRouter = withRouter(Navbar);

  let Sidebar = null;
  if (
    props.auth.isAuthenticated &&
    props.auth.user.status === "approved" &&
    window.location.pathname !== "/ideal"
  ) {
    Sidebar = !isMobileOnly ? withRouter(SidebarUser) : null;
  }
  //   if (
  //     (props.auth.user.identityDocumentUploaded ||
  //       localStorage.getItem("identityDocumentUploaded") === "true") &&
  //     (props.auth.user.poaDocumentUploaded ||
  //       localStorage.getItem("poaDocumentUploaded") === "true")
  //   ) {
  //     Sidebar = !isMobileOnly ? withRouter(SidebarUser) : null;
  //   }
  // }
  // if (window.location.href.includes("login")) {
  //   Sidebar = SidebarLogin;
  // }
  if (window.location.href.includes("register")) {
    Sidebar = !isMobileOnly ? SidebarRegister : null;
  }

  return (
    <>
      <Container style={{ height: "100vh" }} fluid>
        <Row className="h-100">
          {Sidebar ? (
            <Col
              className={[
                "sidebar px-0",
                window.location.href.includes("register") ? "register" : "",
              ].join(" ")}
            >
              <Sidebar />
            </Col>
          ) : null}
          <Col
            className={[
              classes.Content,
              !props.auth.isAuthenticated || window.location.pathname === "/"
                ? "mx-auto"
                : "",
            ].join(" ")}
          >
            <HeaderWithRouter logoutClicked={onLogoutClick} />
            {props.children}
          </Col>
        </Row>
      </Container>

      <NotificationContainer />

      <AcceptDeclarations />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Layout);
