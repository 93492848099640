import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { getBankDetails } from "../../../redux/actions/userActions";
import BankDetailsModal from "../../../components/Modals/BankDetails/BankDetails";
import MissingBankDetails from "../../../components/Modals/MissingBankDetails/MissingBankDetails";
import classes from "./BankDetails.module.css";
import moment from "moment";

const BankDetails = () => {
  const [state, setState] = useState({
    addBankDetailsErrors: "",
    showAddEditIntlBankDetailsModal: false,
    selectedBankDetails: {},
    loading: true,
  });
  const [bankDetails, setBankDetails] = useState([]);

  useEffect(() => {
    getBankDetails("all", "all")
      .then(res => {
        setBankDetails(res.data);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.log(err));
  }, []);

  const handleModalShow = (target, selectedBankDetails) => {
    if (target === "showAddEditIntlBankDetailsModal") {
      setState(prevState => {
        return { ...prevState, selectedBankDetails };
      });
    }

    setState(prevState => {
      return { ...prevState, [target]: true };
    });
  };

  const handleModalClose = target => {
    setState(prevState => {
      return { ...prevState, [target]: false };
    });
  };

  return (
    <>
      <Container className="mb-5" fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Bank Details
              <Button
                type="button"
                className="blueButton float-right"
                style={{ fontWeight: "normal", marginTop: 0 }}
                onClick={() =>
                  handleModalShow("showAddEditIntlBankDetailsModal", "")
                }
              >
                Add Bank
              </Button>
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4 py-3 greyBg topBorders bottomBorders">
          <Col xs="12">
            <p className={classes.smallerText}>
              Providing us with an Approved Bank Account enables us to send
              money to you quickly and easily.
            </p>
          </Col>

          <Col xs="12">
            <p className={classes.biggerText}>Approved Bank Accounts</p>
          </Col>

          <Col xs="12">
            {bankDetails.bankDetails && bankDetails.bankDetails.length > 0
              ? bankDetails.bankDetails.map(bankDetails =>
                  bankDetails.status === "approved" ? (
                    <div
                      key={bankDetails._id}
                      className="cardWithLeftBorder m-1"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleModalShow(
                          "showAddEditIntlBankDetailsModal",
                          bankDetails
                        )
                      }
                    >
                      <div className="cardBorder green" />
                      <Card
                        className={[
                          "customCardsStyle customFundingMethodCardsStyle",
                          classes.BankDetailsCardPreview,
                        ].join(" ")}
                      >
                        <Card.Body className="pl-3">
                          {bankDetails.bankCountry === "GB" ? (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NUMBER
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountNumber}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SORT CODE
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSortCode}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  IBAN
                                </div>
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "4px",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {bankDetails.bankIBAN}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SWIFT/BIC
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSwift}
                                </span>
                              </div>
                            </div>
                          )}

                          <div>
                            <div
                              className="float-left"
                              style={{ width: "135px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                DATE ADDED
                              </div>
                              <span style={{ fontSize: "0.9375rem" }}>
                                {moment(bankDetails.timestamp * 1000).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                            <div
                              className="float-left"
                              style={{ maxWidth: "145px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                BANK NAME
                              </div>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "0.9375rem",
                                }}
                              >
                                {bankDetails.bankName}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ) : null
                )
              : null}
          </Col>

          <Col xs="12" className="mt-5">
            <p className={classes.biggerText}>Pending Bank Accounts</p>
          </Col>

          <Col xs="12">
            {bankDetails.bankDetails && bankDetails.bankDetails.length > 0
              ? bankDetails.bankDetails.map(bankDetails =>
                  bankDetails.status === "pending" ? (
                    <div
                      key={bankDetails._id}
                      className="cardWithLeftBorder m-1"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleModalShow(
                          "showAddEditIntlBankDetailsModal",
                          bankDetails
                        )
                      }
                    >
                      <div className="cardBorder orange" />
                      <Card
                        className={[
                          "customCardsStyle customFundingMethodCardsStyle",
                          classes.BankDetailsCardPreview,
                        ].join(" ")}
                      >
                        <Card.Body className="pl-3">
                          {bankDetails.bankCountry === "GB" ? (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NUMBER
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountNumber}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SORT CODE
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSortCode}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  IBAN
                                </div>
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "4px",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {bankDetails.bankIBAN}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SWIFT/BIC
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSwift}
                                </span>
                              </div>
                            </div>
                          )}

                          <div>
                            <div
                              className="float-left"
                              style={{ width: "135px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                DATE ADDED
                              </div>
                              <span style={{ fontSize: "0.9375rem" }}>
                                {moment(bankDetails.timestamp * 1000).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                            <div
                              className="float-left"
                              style={{ maxWidth: "145px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                BANK NAME
                              </div>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "0.9375rem",
                                }}
                              >
                                {bankDetails.bankName}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ) : null
                )
              : null}
          </Col>
        </Row>
      </Container>

      <MissingBankDetails />

      {state.showAddEditIntlBankDetailsModal ? (
        <BankDetailsModal
          handleModalClose={handleModalClose}
          bankDetails={state.selectedBankDetails}
        />
      ) : null}
    </>
  );
};

export default BankDetails;
