import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Card, Button } from "react-bootstrap";
import classes from "./Deposit.module.css";
import { requestGcenWirePayment } from "../../../../redux/actions/userActions";
import { getReducedAmount } from "../../../../utils/utils";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [bankDetails, setBankDetails] = useState(null);

  useEffect(() => {
    let depositData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      depositCurrency: props.selectedAccount.baseCurrency,
      depositAmountSent: props.depositAmount,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      depositType: props.paymentMethod.id,
    };
    props.requestGcenWirePayment(depositData);
  }, []);

  useEffect(() => {
    console.log(props.success);
    if (Object.keys(props.success).length > 0) {
      if (props.success.success) setBankDetails(props.success.bankDetails);
      else {
        localStorage.setItem("errorMessage", props.success.error);
        window.location.href = "/deposit-funds/failed";
      }
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  const handleFinish = () => {
    props.setPaymentResult("SUCCESS");
    props.nextStep("success_wire");
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="7">
        {bankDetails !== null ? (
          <>
            <div
              className="wizardStepsParagraph"
              style={{ fontSize: "1.125rem", fontWeight: 500 }}
            >
              {props.selectedAccount.baseCurrency} Bank Wire Transfer Details:
            </div>
            <div className="wizardStepsParagraph" style={{ fontSize: "1rem" }}>
              Account Name: {bankDetails.AccountHolder}
              <br />
              {bankDetails.BankAccountNumber ? (
                <>
                  Account Number: {bankDetails.BankAccountNumber}
                  <br />
                </>
              ) : null}
              {bankDetails.BankAccountNumber ? (
                <>
                  Sort Code: {bankDetails.SpecialId}
                  <br />
                </>
              ) : null}
              IBAN: {bankDetails.IBAN}
              <br />
              SWIFT/BIC: {bankDetails.SwiftCode}
              <br />
              Bank Name: {bankDetails.BankName}
              <br />
              <strong>Reference: {bankDetails.BankReference}</strong>
            </div>

            <div className="wizardStepsParagraph">
              Please make sure that you include the reference code so we know
              where to allocate funds as quick as possible when they arrive.
            </div>

            <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
              <div className="wizardFundAmount">
                <Button
                  className="cardButton float-left mt-3 ml-1"
                  style={{
                    backgroundColor: "var(--color-dark-blue1)",
                  }}
                  onClick={() => props.nextStep("select_amount")}
                >
                  BACK
                </Button>
                <Button
                  className="cardButton float-right mt-3"
                  style={{
                    backgroundColor: "var(--color-dark-blue1)",
                  }}
                  onClick={handleFinish}
                >
                  FINISH{" "}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="wizardStepsParagraph">Please wait...</div>
        )}
      </Col>
      <Col md="4">
        <Card
          className={[
            "customCardsStyle customCardsStyleMediumFont",
            classes.PaymentMethodCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
              {props.selectedAccount.number}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">DEPOSIT METHOD</div>
              {props.paymentMethod.method}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">DEPOSIT AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.depositAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">DEPOSIT FEE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {props.paymentMethod.fees}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">DEPOSIT AMOUNT AFTER FEE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {getReducedAmount(
                  props.depositAmount,
                  props.paymentMethod.fees
                )}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestGcenWirePayment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestGcenWirePayment })(Step);
