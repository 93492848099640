import React, { useState, useEffect } from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { getLiveAccounts } from "../../../../redux/actions/userActions";

const Step = props => {
  const [loading, setLoading] = useState(true);
  const [accountsLive, setLiveAccounts] = useState([]);

  useEffect(() => {
    getLiveAccounts("approved", "all")
      .then(res => {
        if (props.preselectAccount) {
          Object.entries(res.data).map(account => {
            if (
              parseInt(account[1].number) === parseInt(props.preselectAccount)
            ) {
              props.selectAccount(account[1]);
            }
          });
        }

        setLiveAccounts(res.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        {/*<div className="wizardStepsParagraph">*/}
        {/*  <strong className="text-danger">*/}
        {/*    Deposits are temporarily in testing phase. Please check back later.*/}
        {/*  </strong>*/}
        {/*</div>*/}

        {accountsLive.length > 0 ? (
          <div className="wizardStepsParagraph">
            Please select the account that you would like to Deposit Funds to:
          </div>
        ) : null}

        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : null}

        {accountsLive.length > 0 ? (
          <ul className="list-unstyled wizardAccountList">
            {Object.entries(accountsLive).map(account => (
              <li key={account[1].number}>
                <div className="cardBorder red" />

                <span className="cardFlag accTypeWizard mt-3 mx-2 ml-md-2 mr-md-5">
                  LIVE
                </span>

                <span className="mx-2 ml-md-2 mr-md-5">
                  <div className="cardCaptionTitle">ACCOUNT NAME</div>
                  {account[1].nickname}
                </span>

                <span
                  className="mx-2 ml-md-2 mr-md-5"
                  style={{ minWidth: "85px" }}
                >
                  <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
                  <div>{account[1].number || "-"}</div>
                </span>

                <span className="mx-2 ml-md-2 mr-md-5">
                  <div className="cardCaptionTitle">PLATFORM</div>
                  {account[1].platform}{" "}
                  <small style={{ fontSize: "9px" }}>[iQuoto-live]</small>
                </span>

                <span
                  className="mx-2 ml-md-2 mr-md-5"
                  style={{ minWidth: "45px" }}
                >
                  <div className="cardCaptionTitle">LEVERAGE</div>
                  {`1:${account[1].leverage}`}
                </span>

                <span
                  className="mx-2 ml-md-2 mr-md-5"
                  style={{ minWidth: "125px" }}
                >
                  <div className="cardCaptionTitle">BALANCE</div>
                  <span>
                    {account[1].balance
                      ? parseFloat(account[1].balance).toFixed(2)
                      : 0}{" "}
                  </span>
                  <span className="cardFlag cardCurrencyFlag float-right">
                    {account[1].baseCurrency}
                  </span>
                </span>

                <Button
                  className="cardButton ml-3"
                  style={{
                    backgroundColor: "var(--color-dark-blue1)",
                  }}
                  onClick={() => props.selectAccount(account[1])}
                >
                  SELECT
                </Button>
              </li>
            ))}
          </ul>
        ) : null}
      </Col>
    </Row>
  );
};

export default Step;
