import React, { useState, useEffect } from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { getLiveAccounts } from "../../../../redux/actions/userActions";
import { Link } from "react-router-dom";
import NoFundedAccounts from "../../../../components/Modals/NoFundedAccounts/NoFundedAccountsInternal";

const Step = props => {
  const [loading, setLoading] = useState(true);
  const [accountsLive, setLiveAccounts] = useState([]);
  const [allZeroFundedAccounts, setAllZeroFundedAccounts] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getLiveAccounts("approved", "all")
      .then(res => {
        let allZero = true;
        Object.entries(res.data).map(account => {
          if (
            props.preselectAccount &&
            parseInt(account[1].number) === parseInt(props.preselectAccount)
          ) {
            props.selectAccount(account[1]);
          }
          if (parseFloat(account[1].balance) > 0) {
            allZero = false;
          }
        });

        setLiveAccounts(res.data);
        setLoading(false);
        setAllZeroFundedAccounts(allZero);
        setShowModal(allZero);
      })
      .catch(err => console.log(err));
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        {!allZeroFundedAccounts && accountsLive.length > 0 ? (
          <div className="wizardStepsParagraph">
            Please select the account that you would like to transfer from:
          </div>
        ) : null}

        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : null}

        {allZeroFundedAccounts && !loading ? (
          <div
            className="wizardStepsParagraph mx-auto"
            style={{ maxWidth: "530px" }}
          >
            Looks like you don’t have any funded accounts yet. Click here to
            deposit funds.
            <br />
            <Button
              className="cardButton mt-4 mx-auto"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              as={Link}
              to={`/deposit-funds/`}
            >
              FUND NOW
            </Button>
          </div>
        ) : accountsLive.length > 0 ? (
          <ul className="list-unstyled wizardAccountList">
            {Object.entries(accountsLive).map(account => (
              <li key={account[1].number}>
                <div className="cardBorder red" />

                <span className="cardFlag accTypeWizard mt-3 ml-2 mr-5">
                  LIVE
                </span>

                <span className="ml-2 mr-5">
                  <div className="cardCaptionTitle">ACCOUNT NAME</div>
                  {account[1].nickname}
                </span>

                <span className="ml-2 mr-5" style={{ minWidth: "85px" }}>
                  <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
                  <div>{account[1].number || "-"}</div>
                </span>

                <span className="ml-2 mr-5">
                  <div className="cardCaptionTitle">PLATFORM</div>
                  {account[1].platform}{" "}
                  <small style={{ fontSize: "9px" }}>[iQuoto-live]</small>
                </span>

                <span className="ml-2 mr-5" style={{ minWidth: "45px" }}>
                  <div className="cardCaptionTitle">LEVERAGE</div>
                  {`1:${account[1].leverage}`}
                </span>

                <span className="ml-2 mr-5" style={{ minWidth: "125px" }}>
                  <div className="cardCaptionTitle">BALANCE</div>
                  <span>
                    {account[1].balance
                      ? parseFloat(account[1].balance).toFixed(2)
                      : 0}{" "}
                  </span>
                  <span className="cardFlag cardCurrencyFlag float-right">
                    {account[1].baseCurrency}
                  </span>
                </span>

                {account[1].balance > 0 ? (
                  <Button
                    className="cardButton ml-3"
                    style={{
                      backgroundColor: "var(--color-dark-blue1)",
                    }}
                    onClick={() =>
                      props.selectAccount(
                        "selectedAccountFrom",
                        account[1],
                        "select_account_to"
                      )
                    }
                  >
                    SELECT
                  </Button>
                ) : (
                  <Button
                    className="cardButton ml-3 px-0"
                    as={Link}
                    to={`/deposit-funds/account/${account[1].number}`}
                  >
                    FUND NOW
                  </Button>
                )}
              </li>
            ))}
          </ul>
        ) : null}
      </Col>

      {showModal ? (
        <NoFundedAccounts handleModalClose={handleModalClose} />
      ) : null}
    </Row>
  );
};

export default Step;
