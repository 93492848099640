import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDocuments } from "../../../../redux/actions/userActions";
import { Button, Col, Row } from "react-bootstrap";
import classes from "../UploadDocuments.module.css";
import {
  gcenKycCountries,
  gcenKycDocsRequirements,
  kycCardsText,
} from "../../../../config/constants";

const ProofOfAddress = props => {
  const [requiredDocs, setRequiredDocs] = useState([]);
  const [documents, setDocuments] = useState({});

  useEffect(() => {
    const userRiskCategory = gcenKycCountries[props.auth.user.country];
    const docsRequirements = gcenKycDocsRequirements[userRiskCategory];
    setRequiredDocs(docsRequirements.proof_of_address);

    getDocuments("all")
      .then(res => {
        // console.log(res.data);
        setDocuments(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h6 className="mx-4">
            To verify your address, we will require you to submit a form of
            document to verify your address.{" "}
            <span className="text-danger">
              Upload one of these types of documents.
            </span>
            <br />
            <br />
            Please make sure that all proof of address documents are dated
            within the last 3 months.
          </h6>
        </Col>
      </Row>

      <Row className="mx-4 p-5 greyBg roundedBox">
        {requiredDocs.map(val => (
          <Col
            key={val}
            className={[
              classes.box,
              documents[val] && documents[val].uploaded
                ? "border border-dark"
                : "",
            ].join(" ")}
            onClick={() => props.changeDocument(val)}
          >
            <div className={classes.imgWrapper}>
              <img
                src={kycCardsText[val].image}
                className="mx-auto d-block"
                alt={val}
              />
            </div>
            <div className={classes.title}>{kycCardsText[val].title}</div>
            <p className={classes.text}>{kycCardsText[val].text}</p>
          </Col>
        ))}
      </Row>

      <Row>
        <Col className="mt-5 d-flex justify-content-around">
          <Button
            className={[classes.btn, classes.btnBack, "roundedBox"].join(" ")}
            onClick={() => props.changeDocument("choose_method")}
          >
            BACK
          </Button>
        </Col>
      </Row>
    </>
  );
};

ProofOfAddress.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProofOfAddress);
