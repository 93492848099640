import CryptoJS from "crypto-js";
import { encryptionKey } from "../config/config";
import { countryList, industries, fieldOfStudy } from "../config/constants";

export const encryptData = data => {
  return CryptoJS.AES.encrypt(data, encryptionKey).toString();
};

export const decryptData = data => {
  return CryptoJS.AES.decrypt(data, encryptionKey).toString(CryptoJS.enc.Utf8);
};

export const encryptDataBase64 = data => {
  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(data.toString())
  );
};

export const decryptDataBase64 = data => {
  return CryptoJS.enc.Base64.parse(data.toString()).toString(CryptoJS.enc.Utf8);
};

export const cutLongText = (text, limit) => {
  const lmt = parseInt(limit);
  return text.length > lmt ? text.substr(0, lmt) + "..." : text;
};

export const countryListReactSelect = () => {
  let countries = [];
  Object.keys(countryList).map((value, index) =>
    countries.push({ value, label: countryList[value] })
  );
  return countries;
};

export const getIndustriesListReactSelect = () => {
  let items = [];
  industries.map(value => items.push({ value, label: value }));
  return items;
};

export const getFieldOfStudyListReactSelect = () => {
  let items = [];
  fieldOfStudy.map(value => items.push({ value, label: value }));
  return items;
};

export const bankCodesListReactSelect = banks => {
  let bankCodes = [];
  Object.keys(banks).map((value, index) =>
    bankCodes.push({ value, label: banks[value] })
  );
  return bankCodes;
};

export const customerBankCodesListReactSelect = banks => {
  let bankCodes = [];
  banks.map(value =>
    bankCodes.push({ value: value._id, label: value.bankName })
  );
  return bankCodes;
};

export const formatDateForDateFilter = date => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getBirthDaysReactSelect = () => {
  let days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ value: i, label: i });
  }
  return days;
};

export const getBirthMonthsReactSelect = () => {
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ value: i, label: i });
  }
  return months;
};

export const getBirthYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y - 100; i <= y; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const getExpiryYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y; i <= y + 50; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const getBankDetailsSelect = bankAccounts => {
  let accounts = [];
  Object.entries(bankAccounts).map(account =>
    accounts.push({
      value: account[0],
      label: `${account[1].bankName}, #${account[1].bankAccountNumber}`,
    })
  );
  return accounts;
};

export const getReducedAmount = (amount, reducer) => {
  return reducer.includes("%")
    ? (amount - amount * (parseFloat(reducer) / 100)).toFixed(2)
    : (amount - parseFloat(reducer)).toFixed(2);
};
export const getIncreasedAmount = (amount, increaser) => {
  return increaser.includes("%")
    ? (amount + amount * (parseFloat(increaser) / 100)).toFixed(2)
    : (amount + parseFloat(increaser)).toFixed(2);
};

export const getCategoryName = () => {
  if (window.location.href.includes("trading")) {
    return "MY ACCOUNTS";
  } else if (
    window.location.href.includes("funds") ||
    window.location.href.includes("trans")
  ) {
    return "FUNDING";
  } else if (window.location.href.includes("platforms")) {
    return "PLATFORMS";
  } else if (window.location.href.includes("document")) {
    return "VERIFICATION";
  } else if (window.location.href.includes("professional-client-status")) {
    return "PROFESSIONAL CLIENT STATUS";
  } else return "";
};

export const clearLocalStorage = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("step");
  localStorage.removeItem("substep");
  localStorage.removeItem("identityDocumentUploaded");
  localStorage.removeItem("poaDocumentUploaded");
};

export const getChecksum = (...data) => {
  return CryptoJS.SHA256(data.join("")).toString();
};

export const getCookie = cname => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};
