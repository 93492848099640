import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../../config/config";
import { Button, Col, Form, Row } from "react-bootstrap";
import classes from "../UploadDocuments.module.css";
import {
  getDocuments,
  saveDocuments,
} from "../../../../redux/actions/userActions";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import idCardFront from "../../../../assets/images/documents/nationalidcard_front.png";
import idCardBack from "../../../../assets/images/documents/nationalidcard_back.png";
import { NotificationManager } from "react-notifications";

// Register the plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const IdCard = props => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const fileID = props.auth.user.gcenApiClientId
    ? props.auth.user.gcenApiClientId
    : props.auth.user.id;

  useEffect(() => {
    getDocuments("id")
      .then(res => {
        setUploadedFile(res.data.id);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      window.location.reload();
    }

    if (Object.keys(props.errors).length > 0) {
      setButtonsDisabled(false);
    }
  }, [props]);

  const handleSaveDocuments = () => {
    saveDocuments("id", uploadedFile)
      .then(res => {
        if (res.data.success) window.location.reload();
        else NotificationManager.error("Something went wrong!", "", 5000);
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      <Row className="mx-4 mb-3  pt-5 pb-3 greyBg roundedBox">
        <Col xs="12" className={[classes.content, "mx-auto"].join(" ")}>
          <p className={classes.text2}>
            Please upload a valid copy of the front of your ID card:
          </p>

          <div className={[classes.whiteBox, "roundedBox"].join(" ")}>
            <img
              src={idCardFront}
              className="mx-auto d-block"
              alt="driving licence"
            />
            <div className={[classes.title, "mt-3"].join(" ")}>
              ID CARD (FRONT)
            </div>
            <p className={[classes.text, "pt-3"].join(" ")}>
              Front of ID card must have
              <br />
              all corners in frame
            </p>

            {uploadedFile &&
            uploadedFile.documentFront &&
            uploadedFile.statusFront === "uploaded" ? (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Front preview</Form.Label>
                  <img
                    src={`${API_URL}/public/files/${uploadedFile.documentFront}`}
                    className={classes.imgPreview}
                    alt=""
                  />
                </Form.Group>
              </Form.Row>
            ) : (
              <FilePond
                onupdatefiles={fileItems => {
                  if (fileItems.length > 0) {
                    let files = fileItems.map(fileItem => fileItem.file);
                    if (files.length > 0) {
                      setUploadedFile(prevState => {
                        return {
                          ...prevState,
                          documentFront: files[0].name,
                        };
                      });
                    }
                  }
                }}
                fileRenameFunction={file => {
                  let date = new Date();
                  let time = date.getTime();
                  //prettier-ignore
                  return `${fileID}_${time}_${file.basename}${file.extension}`;
                }}
                name={"file"}
                server={`${API_URL}/upload`}
              />
            )}
          </div>
        </Col>
        <Col xs="12" className={[classes.content, "mx-auto mt-5"].join(" ")}>
          <p className={classes.text2}>
            Please upload a valid copy of the back of your ID card:
          </p>

          <div className={[classes.whiteBox, "roundedBox"].join(" ")}>
            <img
              src={idCardBack}
              className="mx-auto d-block"
              alt="driving licence"
            />
            <div className={[classes.title, "mt-3"].join(" ")}>
              ID CARD (BACK)
            </div>
            <p className={[classes.text, "pt-3"].join(" ")}>
              Back of ID card must have
              <br />
              all corners in frame
            </p>

            {uploadedFile &&
            uploadedFile.documentBack &&
            uploadedFile.statusBack === "uploaded" ? (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Back preview</Form.Label>
                  <img
                    src={`${API_URL}/public/files/${uploadedFile.documentBack}`}
                    className={classes.imgPreview}
                    alt=""
                  />
                </Form.Group>
              </Form.Row>
            ) : (
              <FilePond
                onupdatefiles={fileItems => {
                  if (fileItems.length > 0) {
                    let files = fileItems.map(fileItem => fileItem.file);
                    if (files.length > 0) {
                      setUploadedFile(prevState => {
                        return {
                          ...prevState,
                          documentBack: files[0].name,
                        };
                      });
                    }
                  }
                }}
                fileRenameFunction={file => {
                  let date = new Date();
                  let time = date.getTime();
                  //prettier-ignore
                  return `${fileID}_${time}_${file.basename}${file.extension}`;
                }}
                name={"file"}
                server={`${API_URL}/upload`}
              />
            )}
          </div>
        </Col>
        <Col
          xs="12"
          className="mt-5 d-flex justify-content-around d-flex justify-content-around"
        >
          <Button
            className={[classes.btn, classes.btnBack, "roundedBox"].join(" ")}
            onClick={() => props.changeDocument("proof_of_identity")}
          >
            BACK
          </Button>
          {uploadedFile &&
          uploadedFile.uploaded &&
          uploadedFile.documentBack ? null : (
            <Button
              className={[classes.btn, classes.btnSubmit, "roundedBox"].join(
                " "
              )}
              onClick={() => handleSaveDocuments()}
              disabled={buttonsDisabled}
            >
              SUBMIT
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

IdCard.propTypes = {
  auth: PropTypes.object.isRequired,
  saveDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { saveDocuments })(IdCard);
