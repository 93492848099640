import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  GET_SUCCESS,
  SET_CURRENT_USER,
  USER_LOADING,
} from "./types";
import { API_URL } from "../../config/config";
import { clearLocalStorage } from "../../utils/utils";

// Get user data
export const getUser = email => {
  return axios.post(API_URL + "/api/users/profile", { email });
};

// Register User
export const registerUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/register", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    ) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Check if User can register
export const checkUserRegister = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/checkUserRegister", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    ) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Register User
export const registerUserWeb = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/registerWeb", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    ) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Create New Password
export const createNewPassword = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/createNewPassword", userData)
    .then(() => (window.location.href = "/")) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/login", userData)
    .then(res => {
      // Remove registration steps data if exists
      console.log("CLEARING localStorage at login");
      clearLocalStorage();
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      localStorage.setItem("userData", JSON.stringify(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit user
export const editUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/editUser", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit user
export const editUserWeb = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/editUserWeb", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Forgot password
export const resetPassword = email => dispatch => {
  axios
    .post(API_URL + "/api/users/resetPassword", { email })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// New password
export const newPassword = (id, password) => dispatch => {
  axios
    .post(API_URL + "/api/users/newPassword", { id, password })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit Employment Income
export const updateEmploymentIncome = data => dispatch => {
  axios
    .post(API_URL + "/api/users/updateEmploymentIncome", data)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit Employment Income Web
export const updateEmploymentIncomeWeb = data => dispatch => {
  axios
    .post(API_URL + "/api/users/updateEmploymentIncomeWeb", data)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit Trading Experience
export const updateTradingExperience = data => dispatch => {
  axios
    .post(API_URL + "/api/users/updateTradingExperience", data)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit Trading Experience Web
export const updateTradingExperienceWeb = data => dispatch => {
  axios
    .post(API_URL + "/api/users/updateTradingExperienceWeb", data)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// New/Edit Trading Preferences
export const updateTradingPreferencesWeb = data => dispatch => {
  axios
    .post(API_URL + "/api/users/updateTradingPreferencesWeb", data)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Resend verification email
export const resendVerificationEmail = (userId, userEmail) => dispatch => {
  return axios
    .post(API_URL + "/api/users/resendVerificationEmail", {
      userId,
      userEmail,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove registration steps data if exists
  console.log("CLEARING localStorage at logout");
  clearLocalStorage();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  // window.location.href = "/";
};
