import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ChooseMethod from "./ChooseMethod";
// import ChooseDocument from "./ChooseDocument";
import ProofOfIdentity from "./ProofOfIdentity/ProofOfIdentity";
import Passport from "./ProofOfIdentity/Passport";
import DrivingLicence from "./ProofOfIdentity/DrivingLicence";
import IdCard from "./ProofOfIdentity/IdCard";
import ProofOfAddress from "./ProofOfAddress/ProofOfAddress";
import BankStatement from "./ProofOfAddress/BankStatement";
import UtilityBill from "./ProofOfAddress/UtilityBill";
import GovernmentLetter from "./ProofOfAddress/GovernmentLetter";
// import SourceOfWealth from "./ProofOfWealth/SourceOfWealth";
import Success from "./Success";
import {
  gcenKycCountries,
  gcenKycDocsRequirements,
} from "../../../config/constants";
import { getDocuments } from "../../../redux/actions/userActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Documents = props => {
  const [showDocumentType, setShowDocumentType] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [docsRequirements, setDocsRequirements] = useState({});

  useEffect(() => {
    const userRiskCategory = gcenKycCountries[props.auth.user.country];
    if (userRiskCategory)
      setDocsRequirements(gcenKycDocsRequirements[userRiskCategory]);

    getDocuments("all")
      .then(res => {
        // console.log(res.data);
        setDocuments(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const changeDocument = type => {
    setShowDocumentType(type);
  };

  const checkAndShowRequiredDocUpload = () => {
    if (documents === null) return false;

    let showStep = showDocumentType;
    let uploadedCategories = {};
    let uploadedDocs = [];

    Object.keys(documents).forEach(doc => {
      if (documents[doc].uploaded) {
        uploadedDocs.push(doc);
      }
    });

    Object.keys(docsRequirements).forEach(category => {
      if (
        docsRequirements[category].filter(element =>
          uploadedDocs.includes(element)
        ).length === 0
      ) {
        showStep = category;
      } else uploadedCategories[category] = 1;
    });

    if (Object.keys(uploadedCategories).length === 0)
      setShowDocumentType("proof_of_identity");
    else if (Object.keys(uploadedCategories).length > 1)
      setShowDocumentType("finished");
    else setShowDocumentType(showStep);
  };

  let Component;
  switch (showDocumentType) {
    case "choose_method": // Choose between uploading and emailing
      Component = ChooseMethod;
      break;

    case "choose_type": // Choose between POI and POA
      checkAndShowRequiredDocUpload();
      break;

    case undefined:
    case "proof_of_identity":
      Component = ProofOfIdentity;
      break;
    case "passport":
      Component = Passport;
      break;
    case "drivers_licence":
      Component = DrivingLicence;
      break;
    case "id":
      Component = IdCard;
      break;

    case "proof_of_address":
      Component = ProofOfAddress;
      break;
    case "bank_statement":
      Component = BankStatement;
      break;
    case "utility_bill":
      Component = UtilityBill;
      break;
    case "government_letter":
      Component = GovernmentLetter;
      break;

    // case "source_of_wealth":
    //   Component = SourceOfWealth;
    //   break;

    case "finished":
      Component = Success;
      break;

    default:
      Component = ChooseMethod;
  }

  return (
    <Container className="mb-4">
      <Row>
        <Col>
          <h1 className="mx-4">Upload Documents</h1>
        </Col>
      </Row>

      {Component && showDocumentType ? (
        <Component changeDocument={changeDocument} />
      ) : (
        <ChooseMethod changeDocument={changeDocument} />
      )}
    </Container>
  );
};

Documents.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Documents);
