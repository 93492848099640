import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/iQuoto-Logo-White.svg";
import { Button } from "react-bootstrap";
import SidebarUserLinks from "./SidebarUserLinks";
import { checkClientsProfessionalStatus } from "../../redux/actions/userActions";

const SidebarUser = props => {
  const [showProfClientApp, setShowProfClientApp] = useState(false);

  useEffect(() => {
    if (
      !(
        props.auth.user.clientStatus &&
        props.auth.user.clientStatus === "professional"
      )
    ) {
      checkClientsProfessionalStatus()
        .then(res => {
          setShowProfClientApp(!res.data.disabledProfessionalClientApplication);
        })
        .catch(err => console.log(err));
    }
  }, []);

  return (
    <div className="sidebarUser">
      <object
        data={logo}
        width="172"
        height="65"
        className="sidebarLogo"
        aria-label="iQuoto.com"
      />

      <SidebarUserLinks />

      {showProfClientApp ? (
        <Button
          className="cardButton d-block mx-auto mt-5 text-light px-0 text-center applyForProfClient"
          style={{
            backgroundColor: "var(--color-orange1)",
          }}
          as={Link}
          to={`/professional-client-status/`}
        >
          Professional Client Status
        </Button>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SidebarUser);
