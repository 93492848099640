import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import classes from "./ProfessionalClients.module.css";
import { submitClientsProfessionalApplication } from "../../../redux/actions/userActions";

const Step = props => {
  const handleProceed = () => {
    if (props.profData.question04 === "No") {
      submitClientsProfessionalApplication(props.profData)
        .then(res => {
          if (res.data.success) props.nextStep(2);
        })
        .catch(err => console.log(err));
    }
    if (
      props.profData.question04 &&
      props.profData.question05 &&
      props.profData.question06
    ) {
      props.nextStep(1);
    }
  };

  return (
    <>
      <Row className="mx-md-4">
        <Col>
          <h1>Professional Client Status - Professional Experience</h1>
        </Col>
      </Row>

      <Row className="mb-5 mx-md-4 p-5 greyBg roundedBox">
        <Col>
          <p>
            <strong>
              You work or have previously worked in the financial sector for at
              least one year in a professional position, which requires
              knowledge of the transactions or services envisaged.
            </strong>
          </p>

          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice01">
                Yes
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice01"
                name="question04"
                value="Yes"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question04 &&
                  props.profData.question04 === "Yes"
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice02">
                No
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice02"
                name="question04"
                value="No"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question04 &&
                  props.profData.question04 === "No"
                }
              />
            </Form.Group>
          </Form.Row>

          {props.profData.question04 === "Yes" ? (
            <>
              <br />
              <br />
              <p>
                <strong>
                  What is the name of your most relevant employer? (We respect
                  your privacy. Your employer will not be contacted).
                </strong>
              </p>

              <Form.Row>
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    name="question05"
                    className="roundedBox border-0"
                    placeholder="NAME OF EMPLOYER"
                    style={{ width: "100%", maxWidth: "550px" }}
                    onChange={e => props.handleChange(e)}
                  />
                </Form.Group>
              </Form.Row>

              <br />
              <br />

              <p>
                <strong>What role did you hold with this employer?</strong>
              </p>

              <Form.Row>
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    name="question06"
                    className="roundedBox border-0"
                    placeholder="JOB ROLE"
                    style={{ width: "100%", maxWidth: "550px" }}
                    onChange={e => props.handleChange(e)}
                  />
                </Form.Group>
              </Form.Row>
            </>
          ) : null}

          <Button
            className={["cardButton px-4", classes.button].join(" ")}
            onClick={() => props.nextStep(-1)}
          >
            BACK
          </Button>
          {props.profData.question04 === "No" ||
          (props.profData.question04 &&
            props.profData.question05 &&
            props.profData.question05.length > 1 &&
            props.profData.question06 &&
            props.profData.question06.length > 1) ? (
            <Button
              className={["cardButton px-4 float-right", classes.button].join(
                " "
              )}
              onClick={handleProceed}
            >
              NEXT
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Step;
