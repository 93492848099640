import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBankDetails } from "../../../redux/actions/userActions";

const MissingBankDetails = props => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.auth.isAuthenticated && props.auth.user.status === "approved") {
      getBankDetails("all", "all")
        .then(res => {
          setShowModal(res.data.bankDetails.length === 0);
        })
        .catch(err => console.log(err));
    }
  }, []);

  const handleHideModal = () => {
    setShowModal(false);
  };

  return showModal ? (
    <Modal show={true} size="lg">
      <Modal.Header>
        <Modal.Title>Submit Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="wizardStepsParagraph px-0">
          Submit your Bank Details and we will verify them to enable quicker
          withdrawals.
        </p>

        <Button
          type="button"
          className="cardButton d-block w-100"
          style={{
            backgroundColor: "var(--color-dark-blue1)",
          }}
          onClick={handleHideModal}
        >
          ADD NOW
        </Button>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between" />
    </Modal>
  ) : null;
};

MissingBankDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MissingBankDetails);
