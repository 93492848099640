import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import SelectAccount from "./Step1";
import SelectPaymentMethod from "./Step2";
import SelectAmount from "./Step3";
import ChooseBank from "./Step3ChooseBank";
import ConfirmBankWithdrawal from "./Step4BankTransfer";
// import ConfirmBankWithdrawal from "./Step4BankTransferGCEN";
// import ZotapayBankWithdrawal from "./Step4BankTransferZotapay";
// import Cashier from "./Step4Cashier";
// import Step4ZotaPay from "./Step4ZotaPay";
import Success from "../Success";
import Error from "../Error";
import { isMobileOnly } from "react-device-detect";

const WithdrawFunds = props => {
  const [state, setState] = useState({
    selectedAccount: {},
    selectedPaymentMethod: {},
    selectedAmount: 0,
    selectedBankDetails: {},
    error: "",
  });
  const [step, setStep] = useState("");
  const [paymentResult, setPaymentResult] = useState("");

  useEffect(() => {
    if (props.match.params.status) {
      if (props.match.params.status === "success") {
        setPaymentResult("SUCCESS");
        setStep("success");
      }
      if (props.match.params.status === "failed") {
        setPaymentResult("FAILED");
        setStep("error");
      }
    } else {
      setStep("select_account");
    }
  }, [props.match.params.status]);

  const stepsHierarchy = [
    "",
    "select_account",
    "select_payment_method",
    "select_amount",
    "wire",
    // "wire_zotapay",
    "confirm_wire",
    // "cashier",
    // "zotapay",
    "success",
    "error",
  ];

  const handleChangeStep = step => {
    setStep(step);
  };

  const handleSelectAccount = account => {
    setState(prevState => {
      return { ...prevState, selectedAccount: account };
    });
    handleChangeStep("select_payment_method");
  };

  const handleSelectWithdrawalMethod = method => {
    setState(prevState => {
      return { ...prevState, selectedPaymentMethod: method };
    });
    handleChangeStep("select_amount");
  };

  const handleUpdateAmount = amount => {
    if (amount !== "") {
      setState(prevState => {
        return { ...prevState, selectedAmount: amount };
      });
    }
  };

  const handleSelectAmount = () => {
    if (parseFloat(state.selectedAmount) > state.selectedAccount.balance) {
      alert("You cannot withdraw more than your account balance!");
      return false;
    }
    if (parseFloat(state.selectedAmount) >= 1) {
      handleChangeStep(state.selectedPaymentMethod.type);
    } else return false;
  };

  const handleChooseBank = bankDetails => {
    setState(prevState => {
      return { ...prevState, selectedBankDetails: bankDetails };
    });
    handleChangeStep("confirm_wire");
  };

  const handleSetPaymentResult = result => {
    if (result === "SUCCESS") {
      setPaymentResult(result);
    }
    if (result === "FAILED") {
      setPaymentResult(result);
    }
  };

  // const handleSetPaymentError = error => {
  //   setState(prevState => {
  //     return { ...prevState, error: error };
  //   });
  // };

  let StepModule;
  switch (step) {
    case "select_account":
      StepModule = (
        <SelectAccount
          nextStep={handleChangeStep}
          selectAccount={handleSelectAccount}
        />
      );
      break;
    case "select_payment_method":
      StepModule = (
        <SelectPaymentMethod
          nextStep={handleChangeStep}
          selectWithdrawalMethod={handleSelectWithdrawalMethod}
          selectedAccount={state.selectedAccount}
        />
      );
      break;
    case "select_amount":
      StepModule = (
        <SelectAmount
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          nextStep={handleChangeStep}
          updateAmount={handleUpdateAmount}
          selectAmount={handleSelectAmount}
          withdrawAmount={state.selectedAmount}
        />
      );
      break;
    case "wire":
      StepModule = (
        <ChooseBank
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          withdrawAmount={state.selectedAmount}
          chooseBank={handleChooseBank}
          nextStep={handleChangeStep}
        />
      );
      break;
    case "confirm_wire":
      StepModule = (
        <ConfirmBankWithdrawal
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          withdrawAmount={state.selectedAmount}
          selectedBankDetails={state.selectedBankDetails}
          nextStep={handleChangeStep}
          setPaymentResult={handleSetPaymentResult}
        />
      );
      break;

    // case "wire_zotapay":
    // case "online_bank":
    // case "offline_bank":
    //   StepModule = (
    //     <ZotapayBankWithdrawal
    //       selectedAccount={state.selectedAccount}
    //       paymentMethod={state.selectedPaymentMethod}
    //       withdrawAmount={state.selectedAmount}
    //       chooseBank={handleChooseBank}
    //       nextStep={handleChangeStep}
    //       setPaymentResult={handleSetPaymentResult}
    //       setPaymentError={handleSetPaymentError}
    //     />
    //   );
    //   break;
    // case "cashier":
    //   StepModule = (
    //     <Cashier
    //       selectedAccount={state.selectedAccount}
    //       paymentMethod={state.selectedPaymentMethod}
    //       withdrawAmount={state.selectedAmount}
    //       nextStep={handleChangeStep}
    //       setPaymentResult={handleSetPaymentResult}
    //       setPaymentError={handleSetPaymentError}
    //     />
    //   );
    //   break;
    // case "zotapay":
    //   StepModule = (
    //     <Step4ZotaPay
    //       selectedAccount={state.selectedAccount}
    //       paymentMethod={state.selectedPaymentMethod}
    //       withdrawAmount={state.selectedAmount}
    //       nextStep={handleChangeStep}
    //       setPaymentResult={handleSetPaymentResult}
    //       setPaymentError={handleSetPaymentError}
    //     />
    //   );
    //   break;

    case "success":
      StepModule = (
        <Success>
          <p className="messageTitle success">WITHDRAWAL CONFIRMED!</p>

          <p className="messageText mx-auto">
            Your withdrawal request is submitted. Once your withdrawal is
            approved your funds will be instantly transferred to your account
            and you will get an email notification.
          </p>
        </Success>
      );
      break;
    case "error":
      StepModule = (
        <Error link="/withdraw-funds">
          <p className="messageTitle error">WITHDRAWAL FAILED!</p>

          <p className="messageText mx-auto">
            Your withdrawal request failed.
            <br />
            Please try again
          </p>

          <p className="messageText mx-auto">
            Reason:{" "}
            {localStorage.getItem("errorMessage")
              ? localStorage.getItem("errorMessage")
              : ""}
          </p>
        </Error>
      );
      break;

    default:
      StepModule = null;
      break;
  }

  return (
    <Container className="mb-5" fluid>
      <Row>
        <Col>
          <h1 className="mx-md-4">Withdraw Funds</h1>
        </Col>
      </Row>
      <Row className="mx-1 mx-md-4 pt-3 greyBg topBorders">
        {!isMobileOnly || (isMobileOnly && step === "select_account") ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              step === "select_account"
                ? "active"
                : stepsHierarchy.indexOf(step) > 1
                ? "finished"
                : "",
            ].join(" ")}
          >
            <div className="wizardStep">
              <FaCheck className="checkmark" />
            </div>
            <span>
              <div className="wizardStepHeadline">STEP 1</div>
              <div className="wizardStepText">Select your account</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && step === "select_payment_method") ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              step === "select_payment_method"
                ? "active"
                : stepsHierarchy.indexOf(step) > 2
                ? "finished"
                : "",
            ].join(" ")}
          >
            <div className="wizardStep">2</div>
            <span>
              <div className="wizardStepHeadline">STEP 2</div>
              <div className="wizardStepText">Choose withdrawal method</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly ||
        (isMobileOnly && step === "select_amount") ||
        step === "wire" ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              step === "select_amount" || step === "wire"
                ? "active"
                : stepsHierarchy.indexOf(step) > 4
                ? "finished"
                : "",
            ].join(" ")}
          >
            <div className="wizardStep">3</div>
            <span>
              <div className="wizardStepHeadline">STEP 3</div>
              <div className="wizardStepText">Choose the amount</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && stepsHierarchy.indexOf(step) > 4) ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              stepsHierarchy.indexOf(step) > 4 ? "active" : "",
              paymentResult === "SUCCESS" ? "finished" : "",
              paymentResult === "FAILED" ? "failed" : "",
            ].join(" ")}
          >
            {paymentResult === "SUCCESS" ? (
              <div className="wizardStep">
                <FaCheck className="checkmark" />
              </div>
            ) : paymentResult === "FAILED" ? (
              <div className="wizardStep">X</div>
            ) : (
              <div className="wizardStep">4</div>
            )}

            <span>
              <div className="wizardStepHeadline">STEP 4</div>
              <div className="wizardStepText">Confirm</div>
            </span>
          </Col>
        ) : null}
      </Row>

      {StepModule ? (
        StepModule
      ) : (
        <Row className="mx-1 mx-md-4 greyBg bottomBorders">&nbsp;</Row>
      )}
    </Container>
  );
};

WithdrawFunds.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(WithdrawFunds);
