import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestCreditCardPayment } from "../../../../redux/actions/userActions";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import classes from "./Deposit.module.css";
import { getReducedAmount } from "../../../../utils/utils";

const Step = props => {
  const [btnSubmit, setBtnSubmit] = useState(false);
  const ccTypeChoices = useRef();

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      if (props.success.paymentUrl)
        window.location.href = props.success.paymentUrl;
      else {
        localStorage.setItem("errorMessage", props.success.error);
        window.location.href = "/deposit-funds/failed";
      }
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  const handleSubmit = () => {
    const ccType = ccTypeChoices.current.value;
    if (!ccType || ccType.length === 0) return false;

    setBtnSubmit(true);
    let depositData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      ccType,
      depositCurrency: props.selectedAccount.baseCurrency,
      depositAmountSent: props.depositAmount,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      depositType: props.paymentMethod.id,
      method: props.paymentMethod.method,
    };
    props.requestCreditCardPayment(depositData);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders">
      {!btnSubmit ? (
        <>
          <Col md="7">
            <div className="wizardStepsParagraph">
              Please choose your credit card:
            </div>

            <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
              <Form.Group>
                <Form.Control
                  as="select"
                  className="roundedBox"
                  ref={ccTypeChoices}
                  required
                >
                  <option value="">...</option>
                  <option value="1">Visa (credit)</option>
                  <option value="3">MasterCard (credit)</option>
                  <option value="114">Visa (debit)</option>
                  <option value="1">Maestro (debit)</option>
                  <option value="119">MasterCard (debit)</option>
                  <option value="1">Visa Electron (debit)</option>
                </Form.Control>
              </Form.Group>

              <Button
                className="cardButton mt-3"
                style={{
                  backgroundColor: "var(--color-dark-blue1)",
                }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </div>
          </Col>
          <Col md="5">
            <div className="cardWithLeftBorder">
              <div className="cardBorder red" />
              <Card
                key={props.paymentMethod.id}
                className={[
                  "customCardsStyle customFundingMethodCardsStyle",
                  classes.PaymentMethodCard,
                ].join(" ")}
              >
                <Card.Body>
                  <div style={{ height: "60px" }}>
                    <div className="float-left">
                      <div className="cardCaptionTitle">FUNDING METHOD</div>
                      {props.paymentMethod.method}
                    </div>
                    <div className="float-right">
                      <img
                        src={props.paymentMethod.image}
                        alt={props.paymentMethod.method}
                      />
                    </div>
                  </div>
                  <div style={{ height: "60px" }}>
                    <div className="float-left">
                      <div className="cardCaptionTitle">FUNDING TIME</div>
                      {props.paymentMethod.time}
                    </div>
                    <div className="float-left pl-3">
                      <div className="cardCaptionTitle">FEES</div>
                      {props.paymentMethod.fees}
                    </div>
                    <div className="float-left pl-1 pt-2">
                      <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                        {props.selectedAccount.baseCurrency}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="cardCaptionTitle">AMOUNT MINUS FEES</div>
                    {getReducedAmount(
                      props.depositAmount,
                      props.paymentMethod.fees
                    )}
                    <span className="cardFlag cardCurrencyFlag cardCurrencyFlagAmount">
                      {props.selectedAccount.baseCurrency}
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </>
      ) : (
        <Col className="py-5">
          <div className="wizardStepsParagraph">Please wait...</div>
        </Col>
      )}
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestCreditCardPayment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  requestCreditCardPayment,
})(Step);
