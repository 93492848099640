import React, { useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { getCategoryName } from "../../utils/utils";
import { isMobileOnly } from "react-device-detect";
import SidebarUserLinks from "./SidebarUserLinks";
import logo from "../../assets/images/iQuoto-Logo-Blue.svg";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
// import { userRoles } from "../../config/constants";

const MyNavbar = props => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  if (window.location.pathname === "/ideal") {
    return false;
  }

  const isHomePage = window.location.pathname === "/";
  const isLoginPage = window.location.href.includes("login");
  const isRegisterPage = window.location.href.includes("register");
  const isForgotPasswordPage = window.location.href.includes("forgot-password");
  const isResetPasswordPage = window.location.href.includes("resetpassword");
  const isEmailConfirmPage = window.location.href.includes("email/confirm");

  if (props.auth.user.continueRegistration && !isRegisterPage) {
    console.log("continueRegistration");
    window.location.href = "/register";
  }

  if (
    props.auth.user.registered &&
    props.auth.user.status !== "approved" &&
    !window.location.href.includes("document")
  ) {
    window.location.href = "/upload-documents";
    // window.location.href = "/documents";
  }

  let category = getCategoryName();

  let logoutBtn = null;
  let loginRegister = null;
  if (props.auth.isAuthenticated) {
    logoutBtn = (
      <span
        id="logoutBtn"
        className="ml-3 mt-1"
        onClick={props.logoutClicked}
        style={{ cursor: "pointer" }}
      >
        <FiLogOut />
      </span>
    );
  } else {
    loginRegister = (
      <Nav className="ml-auto">
        <Nav.Link
          className="pt-3 mr-4 plainLoginBtn"
          onClick={() => (window.location.href = "/")}
        >
          Log in
        </Nav.Link>
        <Nav.Link
          className="orangeRegisterBtn"
          onClick={() => (window.location.href = "/register")}
        >
          Register
        </Nav.Link>
      </Nav>
    );
  }

  const handleNavbarToggle = e => {
    setIsNavbarOpen(e);
  };

  return !isHomePage &&
    !isForgotPasswordPage &&
    !isResetPasswordPage &&
    !isLoginPage &&
    !isRegisterPage ? (
    <Navbar
      expand="lg"
      variant="light"
      onToggle={e => handleNavbarToggle(e)}
      expanded={isNavbarOpen}
      className={isMobileOnly && isNavbarOpen ? "mobileNavbar" : null}
    >
      {(isMobileOnly && !isNavbarOpen) ||
      (!isMobileOnly && props.auth.user.status !== "approved") ? (
        <Navbar.Brand as={Link} className="py-0" to="/">
          <object
            data={logo}
            width="135"
            height="51"
            className="d-block"
            onClick={() => (window.location.href = "/")}
            aria-label="iQuoto.com"
          />
        </Navbar.Brand>
      ) : null}

      {isMobileOnly && !isNavbarOpen ? (
        <span className="headerCategory ms-md-4">{category}</span>
      ) : null}

      <div className="d-flex justify-content-end w-100">
        {!isEmailConfirmPage ? (
          props.auth.user.clientStatus &&
          props.auth.user.clientStatus === "professional" ? (
            <span className="clientStatusBadge professional float-right">
              Professional Client
            </span>
          ) : (
            <span className="clientStatusBadge retail float-right">
              Retail Client
            </span>
          )
        ) : null}
        {!isMobileOnly &&
        props.auth.user.name !== undefined &&
        props.auth.user.name.length > 0 ? (
          <>
            {/*<span className="headerCircle" />*/}
            <div className="ml-2 mr-3 mt-1">
              {props.auth.user.name} {props.auth.user.surname}
            </div>
            {logoutBtn}
          </>
        ) : null}
      </div>

      {!isLoginPage ? loginRegister : null}

      {props.auth.isAuthenticated && isMobileOnly ? (
        <>
          <div
            className={[
              "w-100 d-flex",
              isMobileOnly && !isNavbarOpen
                ? "justify-content-end"
                : "justify-content-between",
              isNavbarOpen ? "py-3 mb-4" : "",
            ].join(" ")}
            style={isNavbarOpen ? { borderBottom: "1px solid #B0B0B0" } : {}}
          >
            {isMobileOnly && isNavbarOpen ? (
              <>
                <div className="ml-1 mt-2 text-white">
                  {/*<span className="headerCircle" />*/}
                  {props.auth.user.name} {props.auth.user.surname}
                </div>
                <IoIosClose
                  className="mobileNavbarClose"
                  onClick={() => handleNavbarToggle(false)}
                />
              </>
            ) : (
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            )}
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            {props.auth.user.status === "approved" ? (
              <SidebarUserLinks hideOnClick={handleNavbarToggle} />
            ) : null}

            {/*<Button*/}
            {/*  variant="link"*/}
            {/*  className="menuItemTitle"*/}
            {/*  onClick={() => {*/}
            {/*    alert("Account Settings");*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Account Settings*/}
            {/*</Button>*/}
            {isNavbarOpen ? (
              <Button
                variant="link"
                className="menuItemTitle"
                onClick={props.logoutClicked}
              >
                Log Out
              </Button>
            ) : null}
          </Navbar.Collapse>
        </>
      ) : null}
    </Navbar>
  ) : null;
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MyNavbar);
