import React, { useState, useEffect } from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { getLiveAccounts } from "../../../../redux/actions/userActions";

const Step = props => {
  const [loading, setLoading] = useState(true);
  const [accountsLive, setLiveAccounts] = useState([]);

  useEffect(() => {
    getLiveAccounts("approved", props.selectedAccountFrom.baseCurrency)
      .then(res => {
        setLiveAccounts(res.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [props.selectedAccountFrom.baseCurrency]);

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        <div className="wizardStepsParagraph">
          Please select the account that you would like to transfer to:
        </div>

        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : null}

        <ul className="list-unstyled wizardAccountList">
          {Object.entries(accountsLive).map(account =>
            account[1]._id !== props.selectedAccountFrom._id ? (
              <li key={account[1].number}>
                <div className="cardBorder red" />

                <span className="cardFlag accTypeWizard mt-3 ml-2 mr-5">
                  LIVE
                </span>

                <span className="ml-2 mr-5">
                  <div className="cardCaptionTitle">ACCOUNT NAME</div>
                  {account[1].nickname}
                </span>

                <span className="ml-2 mr-5" style={{ minWidth: "85px" }}>
                  <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
                  <div>{account[1].number || "-"}</div>
                </span>

                <span className="ml-2 mr-5">
                  <div className="cardCaptionTitle">PLATFORM</div>
                  {account[1].platform}{" "}
                  <small style={{ fontSize: "9px" }}>[iQuoto-live]</small>
                </span>

                <span className="ml-2 mr-5" style={{ minWidth: "45px" }}>
                  <div className="cardCaptionTitle">LEVERAGE</div>
                  {`1:${account[1].leverage}`}
                </span>

                <span className="ml-2 mr-5" style={{ minWidth: "125px" }}>
                  <div className="cardCaptionTitle">BALANCE</div>
                  <span>
                    {account[1].balance
                      ? parseFloat(account[1].balance).toFixed(2)
                      : 0}{" "}
                  </span>
                  <span className="cardFlag cardCurrencyFlag float-right">
                    {account[1].baseCurrency}
                  </span>
                </span>

                <Button
                  className="cardButton ml-3"
                  style={{
                    backgroundColor: "var(--color-dark-blue1)",
                  }}
                  onClick={() =>
                    props.selectAccount(
                      "selectedAccountTo",
                      account[1],
                      "select_amount"
                    )
                  }
                >
                  SELECT
                </Button>
              </li>
            ) : null
          )}
        </ul>
      </Col>
    </Row>
  );
};

export default Step;
