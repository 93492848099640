import React from "react";
import { Col, Row, Button, Card, Form } from "react-bootstrap";
import classes from "./InternalTransfer.module.css";
import { getReducedAmount, getIncreasedAmount } from "../../../../utils/utils";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const handleUpdateAmount = e => {
    props.updateAmount(e.target.value);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="6">
        <div className="wizardStepsParagraph">
          Please enter the amount that you would like to transfer:
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
          <div className="fundingInputValuesWrapper">
            <Form.Control
              type="number"
              id="amount"
              name="amount"
              className="fundingInputValue h-100"
              placeholder="AMOUNT"
              onChange={e => handleUpdateAmount(e)}
              defaultValue={props.transferAmount || ""}
            />
            <span className="cardFlag cardCurrencyFlag">
              {props.selectedAccountFrom.baseCurrency}
            </span>
          </div>

          <div className="wizardFundAmount">
            <Button
              className="cardButton float-left mt-3 ml-1"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={() => props.nextStep("select_account_to")}
            >
              BACK
            </Button>
            <Button
              className="cardButton float-right mt-3"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={props.selectAmount}
            >
              NEXT
            </Button>
          </div>
        </div>
      </Col>
      <Col md="6">
        <Card
          className={[
            "customCardsStyle customInternalTransferCardsStyle",
            classes.InternalTransferCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle pb-1">TRANSFER FROM</div>
              {props.selectedAccountFrom.number}
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">
                BALANCE AVAILABLE TO TRANSFER
              </div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccountFrom.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">TRANSFER AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.transferAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">BALANCE AFTER TRANSFER</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {getReducedAmount(
                  props.selectedAccountFrom.balance,
                  props.transferAmount.toString()
                )}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>

        <Card
          className={[
            "customCardsStyle customInternalTransferCardsStyle",
            classes.InternalTransferCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle pb-1">TRANSFER TO</div>
              {props.selectedAccountTo.number}
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">BALANCE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccountTo.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">TRANSFER AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.transferAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountTo.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">BALANCE AFTER TRANSFER</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {getIncreasedAmount(
                  props.selectedAccountTo.balance,
                  props.transferAmount.toString()
                )}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountTo.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Step;
