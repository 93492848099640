import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  getUser,
  editUser,
  updateEmploymentIncome,
  updateTradingExperience,
} from "../../../redux/actions/authActions";
import {
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect,
} from "../../../utils/utils";
import { countryList } from "../../../config/constants";
import Select from "react-select";

const Profile = props => {
  const [state, setState] = useState({
    selectedUser: {},
    employmentIncome: {},
    tradingExperience: {},
    isSocialLogin: false,
    disableEditUserBtn: false,
    disableEIBtn: false,
    disableTEBtn: false,
    errors: {},
  });

  useEffect(() => {
    getUser()
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            isSocialLogin:
              res.data.user.socialId !== undefined &&
              res.data.user.socialId.length > 0,
            selectedUser: res.data.user,
            employmentIncome: res.data.employmentIncome,
            tradingExperience: res.data.tradingExperience,
          };
        });
      })
      .catch(err => console.log(err));
  }, [props]);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      window.location.reload();
    }

    if (Object.keys(props.errors).length > 0) {
      setState(prevState => {
        return { ...prevState, errors: props.errors };
      });
    }

    setState(prevState => {
      return {
        ...prevState,
        disableEditUserBtn: false,
        disableEIBtn: false,
        disableTEBtn: false,
      };
    });
  }, [props]);

  const handleChangeProfile = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "nationality" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (target === "employmentIncome") {
      const employmentIncome = state.employmentIncome
        ? state.employmentIncome
        : {};
      employmentIncome[name] = value;
      setState(prevState => {
        return { ...prevState, employmentIncome };
      });
    } else if (target === "tradingExperience") {
      const tradingExperience = state.tradingExperience
        ? state.tradingExperience
        : {};
      tradingExperience[name] = value;
      setState(prevState => {
        return { ...prevState, tradingExperience };
      });
    } else {
      const selectedUser = state.selectedUser ? state.selectedUser : {};
      selectedUser[name] = value;
      setState(prevState => {
        return { ...prevState, selectedUser };
      });
    }
  };

  const handleSubmitProfile = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return { ...prevState, disableEditUserBtn: true };
    });

    const { selectedUser } = state;

    if (
      selectedUser.birthDay === undefined ||
      selectedUser.birthMonth === undefined ||
      selectedUser.birthYear === undefined
    ) {
      setState(prevState => {
        return { ...prevState, disableEditUserBtn: false };
      });

      alert("Please, set your birth date.");

      return false;
    }

    if (form.checkValidity()) {
      props.editUser(selectedUser);
    }
  };

  const handleSubmitEmploymentIncome = event => {
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return { ...prevState, disableEIBtn: true };
    });

    const { employmentIncome } = state;

    if (
      employmentIncome.fundSource !== "" &&
      employmentIncome.industry !== "" &&
      employmentIncome.position !== "" &&
      employmentIncome.annualSalary !== "" &&
      employmentIncome.savings !== "" &&
      employmentIncome.experience !== ""
    ) {
      console.log(employmentIncome);
      props.updateEmploymentIncome(employmentIncome);
    }
  };

  const handleSubmitTradingExperience = event => {
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return { ...prevState, disableTEBtn: true };
    });

    const { tradingExperience } = state;

    if (
      tradingExperience.experience !== "" &&
      tradingExperience.requiredMargin !== "" &&
      tradingExperience.closingOrderType !== "" &&
      tradingExperience.leverage !== "" &&
      tradingExperience.reactionToLoss !== ""
    ) {
      props.updateTradingExperience(tradingExperience);
    }
  };

  return (
    <Container>
      <Row className="justify-content-lg-center">
        <Col>
          <h1>Profile</h1>
        </Col>
      </Row>
      <Row className="justify-content-lg-center">
        <Col>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Personal details
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Form onSubmit={e => handleSubmitProfile(e)}>
                    <Form.Text style={{ color: "red" }}>
                      {props.errors.server}
                    </Form.Text>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          disabled
                          defaultValue={state.selectedUser.email}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.email}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          onChange={e => handleChangeProfile(e, "")}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.password}
                          {props.errors.passwordincorrect}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password2"
                          placeholder="Confirm password"
                          onChange={e => handleChangeProfile(e, "")}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.password2}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="2" xs="6">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          as="select"
                          name="title"
                          onChange={e => handleChangeProfile(e, "")}
                          value={state.selectedUser.title}
                        >
                          <option value="">Select title</option>
                          <option>Mr.</option>
                          <option>Mrs.</option>
                          <option>Miss</option>
                          <option>Dr.</option>
                        </Form.Control>
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.title}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="4" xs="6">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          required
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.name}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.name}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Surname</Form.Label>
                        <Form.Control
                          type="text"
                          name="surname"
                          placeholder="Enter your surname"
                          required
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.surname}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.surname}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Col md="6" xs="12">
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Birth day</Form.Label>
                            <Select
                              name="birthDay"
                              required
                              onChange={e => handleChangeProfile(e, "birthDay")}
                              options={getBirthDaysReactSelect()}
                              value={{
                                label: state.selectedUser.birthDay || "",
                                value: state.selectedUser.birthDay || "",
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Birth month</Form.Label>
                            <Select
                              name="birthMonth"
                              required
                              onChange={e =>
                                handleChangeProfile(e, "birthMonth")
                              }
                              options={getBirthMonthsReactSelect()}
                              value={{
                                label: state.selectedUser.birthMonth || "",
                                value: state.selectedUser.birthMonth || "",
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Birth year</Form.Label>
                            <Select
                              name="birthYear"
                              required
                              onChange={e =>
                                handleChangeProfile(e, "birthYear")
                              }
                              options={getBirthYearsReactSelect()}
                              value={{
                                label: state.selectedUser.birthYear || "",
                                value: state.selectedUser.birthYear || "",
                              }}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Mobile phone</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobilePhone"
                          placeholder="Enter your phone number"
                          required
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.mobilePhone}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.mobilePhone}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>First Line Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="addressNo"
                          required
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.addressNo}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.address}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Second Line Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.address}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.address}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          placeholder="Enter your city"
                          required
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.city}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.city}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                          type="text"
                          name="postcode"
                          placeholder="Enter your postcode"
                          required
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={state.selectedUser.postcode}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {props.errors.postcode}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>County</Form.Label>
                        <Form.Control
                          type="text"
                          name="county"
                          maxLength="30"
                          placeholder="Enter your county"
                          onChange={e => handleChangeProfile(e, "county")}
                          defaultValue={state.selectedUser.county}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          name="country"
                          required
                          onChange={e => handleChangeProfile(e, "country")}
                          options={countryListReactSelect()}
                          value={{
                            label: countryList[state.selectedUser.country],
                            value: state.selectedUser.country,
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Nationality</Form.Label>
                        <Select
                          name="country"
                          required
                          onChange={e => handleChangeProfile(e, "nationality")}
                          options={countryListReactSelect()}
                          value={{
                            label: countryList[state.selectedUser.nationality],
                            value: state.selectedUser.nationality,
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-between">
                      <Button
                        variant="secondary"
                        onClick={() => window.location.reload()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={state.disableEditUserBtn}
                      >
                        {state.disableEditUserBtn ? (
                          <>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Submit
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form.Row>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Employment &amp; financial info
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Form onSubmit={e => handleSubmitEmploymentIncome(e)}>
                    <Form.Row>
                      <Form.Group controlId="surveyEmploymentIncomeSelect1">
                        <Form.Label>
                          What is the source of your dealing funds?
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="fundSource"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "employmentIncome")
                          }
                          value={
                            state.employmentIncome
                              ? state.employmentIncome.fundSource
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Employment">Employment</option>
                          <option value="Inheritance">Inheritance</option>
                          <option value="Savings and investment">
                            Savings and investment
                          </option>
                          <option value="Other">Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyEmploymentIncomeSelect2">
                        <Form.Label>Which industry do you work in?</Form.Label>

                        <Form.Control
                          as="select"
                          name="industry"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "employmentIncome")
                          }
                          value={
                            state.employmentIncome
                              ? state.employmentIncome.industry
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Accounting">Accounting</option>
                          <option value="Banking">Banking</option>
                          <option value="Finance">Finance</option>
                          <option value="Business consultancy">
                            Business consultancy
                          </option>
                          <option value="Insurance">Insurance</option>
                          <option value="Other">Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyEmploymentIncomeSelect3">
                        <Form.Label>What position do you hold?</Form.Label>

                        <Form.Control
                          as="select"
                          name="position"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "employmentIncome")
                          }
                          value={
                            state.employmentIncome
                              ? state.employmentIncome.position
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Director">Director</option>
                          <option value="Manager">Manager</option>
                          <option value="Entry level">Entry level</option>
                          <option value="Other">Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyEmploymentIncomeSelect4">
                        <Form.Label>
                          What is your approximate annual salary?
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="annualSalary"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "employmentIncome")
                          }
                          value={
                            state.employmentIncome
                              ? state.employmentIncome.annualSalary
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Greater than £450,000">
                            Greater than £450,000
                          </option>
                          <option value="£200,000 - £499,999">
                            £200,000 - £499,999
                          </option>
                          <option value="£90,000 - £199,999">
                            £90,000 - £199,999
                          </option>
                          <option value="£50,000 - £89,999">
                            £50,000 - £89,999
                          </option>
                          <option value="£15,000 - £49,999">
                            £15,000 - £49,999
                          </option>
                          <option value="Less than £15,000">
                            Less than £15,000
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyEmploymentIncomeSelect5">
                        <Form.Label>
                          What is the value of your savings and investments
                          (excl. any property you own)?
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="savings"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "employmentIncome")
                          }
                          value={
                            state.employmentIncome
                              ? state.employmentIncome.savings
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Greater than £450,000">
                            Greater than £450,000
                          </option>
                          <option value="£200,000 - £499,999">
                            £200,000 - £499,999
                          </option>
                          <option value="£90,000 - £199,999">
                            £90,000 - £199,999
                          </option>
                          <option value="£50,000 - £89,999">
                            £50,000 - £89,999
                          </option>
                          <option value="£10,000 - £49,999">
                            £10,000 - £49,999
                          </option>
                          <option value="Less than £10,000">
                            Less than £10,000
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Button
                      variant="primary"
                      type="submit"
                      className="float-right"
                      disabled={state.disableEIBtn}
                    >
                      Save
                    </Button>
                    <br />
                    <br />
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Trading experience
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Form onSubmit={e => handleSubmitTradingExperience(e)}>
                    <Form.Row>
                      <Form.Group controlId="surveyTradingExpSelect1">
                        <Form.Label>
                          Do you have any trading experience?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="experience"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "tradingExperience")
                          }
                          value={
                            state.tradingExperience
                              ? state.tradingExperience.experience
                                ? "Yes"
                                : "No"
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyTradingExpSelect2">
                        <Form.Label>
                          What would be the required marging for 1 lot (100.000)
                          EURUSD if your leverage is 1:100?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="requiredMargin"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "tradingExperience")
                          }
                          value={
                            state.tradingExperience
                              ? state.tradingExperience.requiredMargin
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="0.10">0.10</option>
                          <option value="0.20">0.20</option>
                          <option value="0.30">0.30</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyTradingExpSelect3">
                        <Form.Label>
                          What type of closing order can you choose to help
                          limit losses?
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="closingOrderType"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "tradingExperience")
                          }
                          value={
                            state.tradingExperience
                              ? state.tradingExperience.closingOrderType
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Hedge">Hedge</option>
                          <option value="Partial">Partial</option>
                          <option value="Stop loss">Stop loss</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyTradingExpSelect4">
                        <Form.Label>
                          The market moves by 2%. Trading with how much leverage
                          would lead to the largest potential profit or loss?
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="leverage"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "tradingExperience")
                          }
                          value={
                            state.tradingExperience
                              ? state.tradingExperience.leverage
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Option 1">Option 1</option>
                          <option value="Option 2">Option 2</option>
                          <option value="Option 3">Option 3</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="surveyTradingExpSelect6">
                        <Form.Label>
                          Assuming your deposited capital is an amount you can
                          afford to lose without affecting your capability to
                          meet your financial obligations, how will you feel /
                          react in case you lose that amount as a result of
                          trading CFDs?
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="reactionToLoss"
                          required
                          onChange={e =>
                            handleChangeProfile(e, "tradingExperience")
                          }
                          value={
                            state.tradingExperience
                              ? state.tradingExperience.reactionToLoss
                              : ""
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Button
                      variant="primary"
                      type="submit"
                      className="float-right"
                      disabled={state.disableTEBtn}
                    >
                      Save
                    </Button>
                    <br />
                    <br />
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  editUser,
  updateEmploymentIncome,
  updateTradingExperience,
})(Profile);
