import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import classes from "./ProfessionalClients.module.css";
import { submitClientsProfessionalApplication } from "../../../redux/actions/userActions";

const Step = props => {
  const handleProceed = () => {
    if (props.profData.question07 && props.profData.question08) {
      submitClientsProfessionalApplication(props.profData)
        .then(res => {
          if (res.data.success) props.nextStep(1);
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <>
      <Row className="mx-md-4">
        <Col>
          <h1>Professional Client Status - Professional Experience</h1>
        </Col>
      </Row>

      <Row className="mb-5 mx-md-4 p-5 greyBg roundedBox">
        <Col>
          <p>
            <strong>
              Is this employer that you have stated above regulated by a
              financial regulatory body?
            </strong>
          </p>

          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice01">
                Yes
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice01"
                name="question07"
                value="Yes"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question07 &&
                  props.profData.question07 === "Yes"
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice02">
                No
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice02"
                name="question07"
                value="No"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question07 &&
                  props.profData.question07 === "No"
                }
              />
            </Form.Group>
          </Form.Row>

          <br />
          <br />

          <p>
            <strong>
              How has this role provided you with experience in CFDs and/or
              Forex?
            </strong>
          </p>

          <Form.Row>
            <Form.Group className="w-100">
              <Form.Control
                as="textarea"
                rows="9"
                name="question08"
                className="roundedBox border-0"
                style={{ width: "100%", maxWidth: "935px" }}
                onChange={e => props.handleChange(e)}
              />
            </Form.Group>
          </Form.Row>

          <Button
            className={["cardButton px-4", classes.button].join(" ")}
            onClick={() => props.nextStep(-1)}
          >
            BACK
          </Button>
          {props.profData.question07 &&
          props.profData.question08 &&
          props.profData.question08.length > 1 ? (
            <Button
              className={["cardButton px-4 float-right", classes.button].join(
                " "
              )}
              onClick={handleProceed}
            >
              SUBMIT
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Step;
