import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Card } from "react-bootstrap";
import classes from "./Deposit.module.css";
import { paymentMethodData } from "../../../../config/config";
import { astropayCountries } from "../../../../config/constants";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        <div className="wizardStepsParagraph">
          Please select your funding method:
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "80px" } : {}}>
          {paymentMethodData.map(method =>
            (props.auth.user.country !== "NL" && method.id === "ideal") ||
            (!astropayCountries.includes(props.auth.user.country) &&
              method.id === "astropay") ? null : (
              <div
                key={method.id}
                className="cardWithLeftBorder m-1"
                style={{ cursor: "pointer" }}
                onClick={() => props.selectFundingMethod(method)}
              >
                <div className="cardBorder red" />
                <Card
                  className={[
                    "customCardsStyle customFundingMethodCardsStyle",
                    classes.PaymentMethodCard,
                  ].join(" ")}
                >
                  <Card.Body>
                    <div style={{ height: "60px" }}>
                      <div className="float-left">
                        <div className="cardCaptionTitle">FUNDING METHOD</div>
                        {method.method}
                      </div>
                      <div className="float-right">
                        <img src={method.image} alt={method.method} />
                      </div>
                    </div>
                    <div style={{ height: "60px" }}>
                      <div className="float-left">
                        <div className="cardCaptionTitle">FUNDING TIME</div>
                        {method.time}
                      </div>
                      <div className="float-left pl-3">
                        <div className="cardCaptionTitle">FEES</div>
                        {method.fees}
                      </div>
                    </div>
                    <div className="cardCaptionParagraph">{method.text}</div>
                  </Card.Body>
                </Card>
              </div>
            )
          )}
        </div>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Step);
