import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import classes from "./ProfessionalClients.module.css";
import { checkUserRegister } from "../../../redux/actions/authActions";

const Step = props => {
  const handleProceed = () => {
    if (
      props.profData.question01 === "No" ||
      (props.profData.question01 === "Yes" && props.profData.question02)
    ) {
      props.nextStep(1);
    }
  };

  return (
    <>
      <Row className="mx-md-4">
        <Col>
          <h1>Professional Client Status - Trade Size and Volume</h1>
        </Col>
      </Row>

      <Row className="mb-5 mx-md-4 p-5 greyBg roundedBox">
        <Col>
          <p>
            <strong>
              You have traded, in significant size, in the spread bet/forex/CFD
              markets or other leveraged products (e.g. indices, shares, spot
              FX, futures, options, other derivatives etc.) at an average
              frequency of 10 transactions per quarter over the previous four
              quarters.
            </strong>
          </p>

          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice01">
                Yes
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice01"
                name="question01"
                value="Yes"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question01 &&
                  props.profData.question01 === "Yes"
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ width: "110px" }}>
            <Form.Group as={Col}>
              <label style={{ marginTop: ".3rem" }} htmlFor="choice02">
                No
              </label>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="choice02"
                name="question01"
                value="No"
                onChange={e => props.handleChange(e)}
                checked={
                  props.profData.question01 &&
                  props.profData.question01 === "No"
                }
              />
            </Form.Group>
          </Form.Row>

          <br />
          <br />

          {props.profData.question01 !== "No" ? (
            <>
              <p>
                <strong>
                  Please provide the average number in the spread bet/Forex/CFDs
                  markets or other leveraged products of significantly-sized
                  trades per quarter over the last four quarters from all
                  brokers/trading partners you trade with.
                </strong>
              </p>

              <Form.Row style={{ width: "250px" }}>
                <Form.Group as={Col}>
                  <label style={{ marginTop: ".3rem" }} htmlFor="choice03">
                    0 to 9 trades average
                  </label>
                </Form.Group>
                <Form.Group as={Col} style={{ maxWidth: "28px" }}>
                  <Form.Check
                    custom
                    inline
                    label=""
                    type="checkbox"
                    id="choice03"
                    name="question02"
                    value="0-9"
                    onChange={e => props.handleChange(e)}
                    checked={
                      props.profData.question02 &&
                      props.profData.question02 === "0-9"
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ width: "250px" }}>
                <Form.Group as={Col}>
                  <label style={{ marginTop: ".3rem" }} htmlFor="choice04">
                    10 to 19 trades average
                  </label>
                </Form.Group>
                <Form.Group as={Col} style={{ maxWidth: "28px" }}>
                  <Form.Check
                    custom
                    inline
                    label=""
                    type="checkbox"
                    id="choice04"
                    name="question02"
                    value="10-19"
                    onChange={e => props.handleChange(e)}
                    checked={
                      props.profData.question02 &&
                      props.profData.question02 === "10-19"
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ width: "250px" }}>
                <Form.Group as={Col}>
                  <label style={{ marginTop: ".3rem" }} htmlFor="choice05">
                    20 to 29 trades average
                  </label>
                </Form.Group>
                <Form.Group as={Col} style={{ maxWidth: "28px" }}>
                  <Form.Check
                    custom
                    inline
                    label=""
                    type="checkbox"
                    id="choice05"
                    name="question02"
                    value="20-29"
                    onChange={e => props.handleChange(e)}
                    checked={
                      props.profData.question02 &&
                      props.profData.question02 === "20-29"
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ width: "250px" }}>
                <Form.Group as={Col}>
                  <label style={{ marginTop: ".3rem" }} htmlFor="choice06">
                    30+ trades average
                  </label>
                </Form.Group>
                <Form.Group as={Col} style={{ maxWidth: "28px" }}>
                  <Form.Check
                    custom
                    inline
                    label=""
                    type="checkbox"
                    id="choice06"
                    name="question02"
                    value="30+"
                    onChange={e => props.handleChange(e)}
                    checked={
                      props.profData.question02 &&
                      props.profData.question02 === "30+"
                    }
                  />
                </Form.Group>
              </Form.Row>
            </>
          ) : null}

          <Button
            className={["cardButton px-4", classes.button].join(" ")}
            onClick={() => props.nextStep(-1)}
          >
            BACK
          </Button>
          {props.profData.question01 === "No" ||
          (props.profData.question01 === "Yes" && props.profData.question02) ? (
            <Button
              className={["cardButton px-4 float-right", classes.button].join(
                " "
              )}
              onClick={handleProceed}
            >
              NEXT
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  checkUserRegister: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { checkUserRegister })(Step);
