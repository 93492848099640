import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

const Step = () => {
  return (
    <Row className="mx-4 pt-5 pb-3 greyBg roundedBox wizardComplete">
      <Col>
        <div className="circle success checkmarkAnimation">
          <FaCheck className="checkmark" />
        </div>

        <p className="messageTitle success">
          PROFESSIONAL CLIENT STATUS
          <br />
          SUBMITTED
        </p>

        <p className="messageText mx-auto">
          Your professional client status application has successfully been
          submitted. If we require any further information we will be in
          contact.
        </p>

        <Button
          className="completeButton success"
          onClick={() => (window.location.href = "/")}
        >
          BACK TO DASHBOARD
        </Button>
      </Col>
    </Row>
  );
};

export default Step;
