import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../config/config";
import { countryList } from "../../../config/constants";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import classes from "./Documents.module.css";
import {
  getDocuments,
  saveDocuments,
  sendApprovalDocuments,
} from "../../../redux/actions/userActions";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Select from "react-select";
import {
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getExpiryYearsReactSelect,
} from "../../../utils/utils";
// Register the plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const Documents = props => {
  const [uploaded, setUploaded] = useState({
    id: null,
    drivers_licence: null,
    proofOfAddress: null,
  });
  const [buttons, setButtons] = useState({
    disabled: false,
  });

  const fileID = props.auth.user.gcenApiClientId
    ? props.auth.user.gcenApiClientId
    : props.auth.user.id;

  useEffect(() => {
    getDocuments()
      .then(res => {
        setUploaded({
          ...res.data,
        });
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      window.location.reload();
    }

    if (Object.keys(props.errors).length > 0) {
      setButtons({
        disabled: false,
      });
    }
  }, [props]);

  const handleSaveDocuments = (e, documentType) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (
      uploaded[documentType].expiryDay === undefined ||
      uploaded[documentType].expiryMonth === undefined ||
      uploaded[documentType].expiryYear === undefined
    ) {
      alert("Set your birth date.");

      return false;
    }

    if (form.checkValidity()) {
      setButtons({
        disabled: true,
      });

      let uploadDocs = null;

      if (documentType === "id") uploadDocs = uploaded.id;

      if (documentType === "drivers_licence")
        uploadDocs = uploaded.drivers_licence;

      if (documentType === "proofOfAddress")
        uploadDocs = uploaded.proofOfAddress;

      if (uploadDocs)
        saveDocuments(documentType, uploadDocs)
          .then(res => {
            console.log(res.data.success);
          })
          .catch(err => console.log(err));
    }
  };

  const handleSendApprovalDocuments = () => {
    setButtons({
      disabled: true,
    });
    sendApprovalDocuments()
      .then(res => {
        console.log(res.data);
      })
      .catch(err => console.error(err));
  };

  const handleChangeDocs = (e, documentType, target) => {
    let name;
    let value;
    if (
      target === "country" ||
      target === "expiryDay" ||
      target === "expiryMonth" ||
      target === "expiryYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setUploaded(prevState => {
      if (!prevState[documentType]) prevState[documentType] = {};

      prevState[documentType][name] = value;
      return {
        ...prevState,
      };
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>Documents</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion>
              {/*<Card>*/}
              {/*  <Card.Header>*/}
              {/*    <Accordion.Toggle as={Button} variant="link" eventKey="0">*/}
              {/*      National ID card*/}
              {/*    </Accordion.Toggle>*/}
              {/*  </Card.Header>*/}
              {/*  <Accordion.Collapse eventKey="0">*/}
              {/*    <Card.Body>*/}
              {/*      <Form onSubmit={e => handleSaveDocuments(e, "id")}>*/}
              {/*        <Form.Text style={{ color: "red" }}>*/}
              {/*          {props.errors.server}*/}
              {/*        </Form.Text>*/}

              {/*        <Form.Row>*/}
              {/*          <Form.Group as={Col} md="6" xs="12">*/}
              {/*            <Form.Label>Country of issuance</Form.Label>*/}
              {/*            <Select*/}
              {/*              name="country"*/}
              {/*              onChange={e => handleChangeDocs(e, "id", "country")}*/}
              {/*              options={countryListReactSelect()}*/}
              {/*              value={{*/}
              {/*                label: uploaded.id*/}
              {/*                  ? countryList[uploaded.id.country]*/}
              {/*                  : "",*/}
              {/*                value: uploaded.id ? uploaded.id.country : "",*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </Form.Group>*/}
              {/*          <Form.Group as={Col} md="6" xs="12">*/}
              {/*            <Form.Label>ID Number</Form.Label>*/}
              {/*            <Form.Control*/}
              {/*              type="text"*/}
              {/*              name="documentNumber"*/}
              {/*              required*/}
              {/*              placeholder="Enter ID Number"*/}
              {/*              onChange={e => handleChangeDocs(e, "id", "")}*/}
              {/*              defaultValue={*/}
              {/*                uploaded.id ? uploaded.id.documentNumber : ""*/}
              {/*              }*/}
              {/*            />*/}
              {/*            <Form.Text style={{ color: "red" }}>*/}
              {/*              {props.errors.documentNumber}*/}
              {/*            </Form.Text>*/}
              {/*          </Form.Group>*/}
              {/*        </Form.Row>*/}

              {/*        <Form.Row>*/}
              {/*          <Form.Group as={Col}>*/}
              {/*            <Form.Label>Day</Form.Label>*/}
              {/*            <Select*/}
              {/*              name="expiryDay"*/}
              {/*              onChange={e =>*/}
              {/*                handleChangeDocs(e, "id", "expiryDay")*/}
              {/*              }*/}
              {/*              options={getBirthDaysReactSelect()}*/}
              {/*              value={{*/}
              {/*                label: uploaded.id ? uploaded.id.expiryDay : "",*/}
              {/*                value: uploaded.id ? uploaded.id.expiryDay : "",*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </Form.Group>*/}
              {/*          <Form.Group as={Col}>*/}
              {/*            <Form.Label>Month</Form.Label>*/}
              {/*            <Select*/}
              {/*              name="expiryMonth"*/}
              {/*              onChange={e =>*/}
              {/*                handleChangeDocs(e, "id", "expiryMonth")*/}
              {/*              }*/}
              {/*              options={getBirthMonthsReactSelect()}*/}
              {/*              value={{*/}
              {/*                label: uploaded.id ? uploaded.id.expiryMonth : "",*/}
              {/*                value: uploaded.id ? uploaded.id.expiryMonth : "",*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </Form.Group>*/}
              {/*          <Form.Group as={Col}>*/}
              {/*            <Form.Label>Year</Form.Label>*/}
              {/*            <Select*/}
              {/*              name="expiryYear"*/}
              {/*              onChange={e =>*/}
              {/*                handleChangeDocs(e, "id", "expiryYear")*/}
              {/*              }*/}
              {/*              options={getExpiryYearsReactSelect()}*/}
              {/*              value={{*/}
              {/*                label: uploaded.id ? uploaded.id.expiryYear : "",*/}
              {/*                value: uploaded.id ? uploaded.id.expiryYear : "",*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </Form.Group>*/}
              {/*        </Form.Row>*/}

              {/*        {!uploaded.id ||*/}
              {/*        (uploaded.id &&*/}
              {/*          uploaded.id.status !== "pending_approval") ? (*/}
              {/*          <Form.Row>*/}
              {/*            <Form.Group as={Col}>*/}
              {/*              <Form.Label>Upload front</Form.Label>*/}

              {/*              <FilePond*/}
              {/*                onupdatefiles={fileItems => {*/}
              {/*                  if (fileItems.length > 0) {*/}
              {/*                    setUploaded(prevState => {*/}
              {/*                      let files = fileItems.map(*/}
              {/*                        fileItem => fileItem.file*/}
              {/*                      );*/}
              {/*                      if (files.length > 0) {*/}
              {/*                        if (!prevState.id) prevState.id = {};*/}

              {/*                        prevState.id.documentFront =*/}
              {/*                          files[0].name;*/}
              {/*                        return {*/}
              {/*                          ...prevState,*/}
              {/*                        };*/}
              {/*                      }*/}
              {/*                    });*/}
              {/*                  }*/}
              {/*                }}*/}
              {/*                fileRenameFunction={file => {*/}
              {/*                  let date = new Date();*/}
              {/*                  let time = date.getTime();*/}
              {/*                  //prettier-ignore*/}
              {/*                  return `${fileID}_${time}_${file.basename}${file.extension}`;*/}
              {/*                }}*/}
              {/*                name={"file"}*/}
              {/*                server={`${API_URL}/upload`}*/}
              {/*              />*/}
              {/*            </Form.Group>*/}

              {/*            <Form.Group as={Col}>*/}
              {/*              <Form.Label>Upload back</Form.Label>*/}

              {/*              <FilePond*/}
              {/*                onupdatefiles={fileItems => {*/}
              {/*                  if (fileItems.length > 0) {*/}
              {/*                    setUploaded(prevState => {*/}
              {/*                      let files = fileItems.map(*/}
              {/*                        fileItem => fileItem.file*/}
              {/*                      );*/}
              {/*                      if (files.length > 0) {*/}
              {/*                        if (!prevState.id) prevState.id = {};*/}

              {/*                        prevState.id.documentBack = files[0].name;*/}
              {/*                        return {*/}
              {/*                          ...prevState,*/}
              {/*                        };*/}
              {/*                      }*/}
              {/*                    });*/}
              {/*                  }*/}
              {/*                }}*/}
              {/*                fileRenameFunction={file => {*/}
              {/*                  let date = new Date();*/}
              {/*                  let time = date.getTime();*/}
              {/*                  //prettier-ignore*/}
              {/*                  return `${fileID}_${time}_${file.basename}${file.extension}`;*/}
              {/*                }}*/}
              {/*                name={"file"}*/}
              {/*                server={`${API_URL}/upload`}*/}
              {/*              />*/}
              {/*            </Form.Group>*/}
              {/*          </Form.Row>*/}
              {/*        ) : null}*/}

              {/*        {uploaded.id && uploaded.id.uploaded ? (*/}
              {/*          <Form.Row>*/}
              {/*            <Form.Group as={Col}>*/}
              {/*              <Form.Label>Front preview</Form.Label>*/}
              {/*              <img*/}
              {/*                src={`${API_URL}/public/files/${uploaded.id.documentFront}`}*/}
              {/*                className={classes.imgPreview}*/}
              {/*                alt=""*/}
              {/*              />*/}
              {/*            </Form.Group>*/}
              {/*            <Form.Group as={Col}>*/}
              {/*              <Form.Label>Back preview</Form.Label>*/}
              {/*              <img*/}
              {/*                src={`${API_URL}/public/files/${uploaded.id.documentBack}`}*/}
              {/*                className={classes.imgPreview}*/}
              {/*                alt=""*/}
              {/*              />*/}
              {/*            </Form.Group>*/}
              {/*          </Form.Row>*/}
              {/*        ) : null}*/}

              {/*        <Form.Row className="d-flex justify-content-between pt-5">*/}
              {/*          <Button*/}
              {/*            variant="secondary"*/}
              {/*            onClick={() => window.location.reload()}*/}
              {/*          >*/}
              {/*            Cancel*/}
              {/*          </Button>*/}

              {/*          {uploaded.id &&*/}
              {/*          uploaded.id.status !== "pending_approval" ? (*/}
              {/*            <>*/}
              {/*              {uploaded.id.uploaded ? (*/}
              {/*                <Button*/}
              {/*                  style={{*/}
              {/*                    backgroundColor: "var(--color-light-blue2)",*/}
              {/*                  }}*/}
              {/*                  disabled={buttons.disabled}*/}
              {/*                  onClick={() =>*/}
              {/*                    handleSendApprovalDocuments("id")*/}
              {/*                  }*/}
              {/*                >*/}
              {/*                  Send for approval*/}
              {/*                </Button>*/}
              {/*              ) : null}*/}

              {/*              <Button*/}
              {/*                variant="primary"*/}
              {/*                type="submit"*/}
              {/*                disabled={buttons.disabled}*/}
              {/*              >*/}
              {/*                Upload*/}
              {/*              </Button>*/}
              {/*            </>*/}
              {/*          ) : null}*/}
              {/*        </Form.Row>*/}
              {/*      </Form>*/}
              {/*    </Card.Body>*/}
              {/*  </Accordion.Collapse>*/}
              {/*</Card>*/}

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Drivers licence
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Form
                      onSubmit={e => handleSaveDocuments(e, "drivers_licence")}
                    >
                      <Form.Text style={{ color: "red" }}>
                        {props.errors.server}
                      </Form.Text>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Country of issuance</Form.Label>
                          <Select
                            name="country"
                            onChange={e =>
                              handleChangeDocs(e, "drivers_licence", "country")
                            }
                            options={countryListReactSelect()}
                            value={{
                              label: uploaded.drivers_licence
                                ? countryList[uploaded.drivers_licence.country]
                                : "",
                              value: uploaded.drivers_licence
                                ? uploaded.drivers_licence.country
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>ID Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="documentNumber"
                            required
                            placeholder="Enter ID Number"
                            onChange={e =>
                              handleChangeDocs(e, "drivers_licence", "")
                            }
                            defaultValue={
                              uploaded.drivers_licence
                                ? uploaded.drivers_licence.documentNumber
                                : ""
                            }
                          />
                          <Form.Text style={{ color: "red" }}>
                            {props.errors.documentNumber}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Day</Form.Label>
                          <Select
                            name="expiryDay"
                            onChange={e =>
                              handleChangeDocs(
                                e,
                                "drivers_licence",
                                "expiryDay"
                              )
                            }
                            options={getBirthDaysReactSelect()}
                            value={{
                              label: uploaded.drivers_licence
                                ? uploaded.drivers_licence.expiryDay
                                : "",
                              value: uploaded.drivers_licence
                                ? uploaded.drivers_licence.expiryDay
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Month</Form.Label>
                          <Select
                            name="expiryMonth"
                            onChange={e =>
                              handleChangeDocs(
                                e,
                                "drivers_licence",
                                "expiryMonth"
                              )
                            }
                            options={getBirthMonthsReactSelect()}
                            value={{
                              label: uploaded.drivers_licence
                                ? uploaded.drivers_licence.expiryMonth
                                : "",
                              value: uploaded.drivers_licence
                                ? uploaded.drivers_licence.expiryMonth
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Year</Form.Label>
                          <Select
                            name="expiryYear"
                            onChange={e =>
                              handleChangeDocs(
                                e,
                                "drivers_licence",
                                "expiryYear"
                              )
                            }
                            options={getExpiryYearsReactSelect()}
                            value={{
                              label: uploaded.drivers_licence
                                ? uploaded.drivers_licence.expiryYear
                                : "",
                              value: uploaded.drivers_licence
                                ? uploaded.drivers_licence.expiryYear
                                : "",
                            }}
                          />
                        </Form.Group>
                      </Form.Row>

                      {!uploaded.drivers_licence ||
                      (uploaded.drivers_licence &&
                        uploaded.drivers_licence.status !==
                          "pending_approval") ? (
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Upload front</Form.Label>
                            <FilePond
                              onupdatefiles={fileItems => {
                                if (fileItems.length > 0) {
                                  setUploaded(prevState => {
                                    let files = fileItems.map(
                                      fileItem => fileItem.file
                                    );
                                    if (files.length > 0) {
                                      if (!prevState.drivers_licence)
                                        prevState.drivers_licence = {};

                                      prevState.drivers_licence.documentFront =
                                        files[0].name;
                                      return {
                                        ...prevState,
                                      };
                                    }
                                  });
                                }
                              }}
                              fileRenameFunction={file => {
                                let date = new Date();
                                let time = date.getTime();
                                //prettier-ignore
                                return `${fileID}_${time}_${file.basename}${file.extension}`;
                              }}
                              name={"file"}
                              server={`${API_URL}/upload`}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Upload back</Form.Label>
                            <FilePond
                              onupdatefiles={fileItems => {
                                if (fileItems.length > 0) {
                                  setUploaded(prevState => {
                                    let files = fileItems.map(
                                      fileItem => fileItem.file
                                    );
                                    if (files.length > 0) {
                                      if (!prevState.drivers_licence)
                                        prevState.drivers_licence = {};

                                      prevState.drivers_licence.documentBack =
                                        files[0].name;
                                      return {
                                        ...prevState,
                                      };
                                    }
                                  });
                                }
                              }}
                              fileRenameFunction={file => {
                                let date = new Date();
                                let time = date.getTime();
                                //prettier-ignore
                                return `${fileID}_${time}_${file.basename}${file.extension}`;
                              }}
                              name={"file"}
                              server={`${API_URL}/upload`}
                            />
                          </Form.Group>
                        </Form.Row>
                      ) : null}

                      {uploaded.drivers_licence &&
                      uploaded.drivers_licence.uploaded ? (
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Front preview</Form.Label>
                            <img
                              src={`${API_URL}/public/files/${uploaded.drivers_licence.documentFront}`}
                              className={classes.imgPreview}
                              alt=""
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Back preview</Form.Label>
                            <img
                              src={`${API_URL}/public/files/${uploaded.drivers_licence.documentBack}`}
                              className={classes.imgPreview}
                              alt=""
                            />
                          </Form.Group>
                        </Form.Row>
                      ) : null}

                      <Form.Row className="d-flex justify-content-between pt-5">
                        <Button
                          variant="secondary"
                          onClick={() => window.location.reload()}
                        >
                          Cancel
                        </Button>

                        {uploaded.drivers_licence &&
                        uploaded.drivers_licence.status !==
                          "pending_approval" ? (
                          <>
                            {/*{uploaded.drivers_licence.uploaded ? (*/}
                            {/*  <Button*/}
                            {/*    style={{*/}
                            {/*      backgroundColor: "var(--color-light-blue2)",*/}
                            {/*    }}*/}
                            {/*    disabled={buttons.disabled}*/}
                            {/*    onClick={() =>*/}
                            {/*      handleSendApprovalDocuments("drivers_licence")*/}
                            {/*    }*/}
                            {/*  >*/}
                            {/*    Send for approval*/}
                            {/*  </Button>*/}
                            {/*) : null}*/}

                            <Button
                              variant="primary"
                              type="submit"
                              disabled={buttons.disabled}
                            >
                              Upload
                            </Button>
                          </>
                        ) : null}
                      </Form.Row>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Passport
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Form onSubmit={e => handleSaveDocuments(e, "passport")}>
                      <Form.Text style={{ color: "red" }}>
                        {props.errors.server}
                      </Form.Text>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Country of issuance</Form.Label>
                          <Select
                            name="country"
                            onChange={e =>
                              handleChangeDocs(e, "passport", "country")
                            }
                            options={countryListReactSelect()}
                            value={{
                              label: uploaded.passport
                                ? countryList[uploaded.passport.country]
                                : "",
                              value: uploaded.passport
                                ? uploaded.passport.country
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Passport Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="documentNumber"
                            required
                            placeholder="Enter Passport Number"
                            onChange={e => handleChangeDocs(e, "passport", "")}
                            defaultValue={
                              uploaded.passport
                                ? uploaded.passport.documentNumber
                                : ""
                            }
                          />
                          <Form.Text style={{ color: "red" }}>
                            {props.errors.documentNumber}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Day</Form.Label>
                          <Select
                            name="expiryDay"
                            onChange={e =>
                              handleChangeDocs(e, "passport", "expiryDay")
                            }
                            options={getBirthDaysReactSelect()}
                            value={{
                              label: uploaded.passport
                                ? uploaded.passport.expiryDay
                                : "",
                              value: uploaded.passport
                                ? uploaded.passport.expiryDay
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Month</Form.Label>
                          <Select
                            name="expiryMonth"
                            onChange={e =>
                              handleChangeDocs(e, "passport", "expiryMonth")
                            }
                            options={getBirthMonthsReactSelect()}
                            value={{
                              label: uploaded.passport
                                ? uploaded.passport.expiryMonth
                                : "",
                              value: uploaded.passport
                                ? uploaded.passport.expiryMonth
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Year</Form.Label>
                          <Select
                            name="expiryYear"
                            onChange={e =>
                              handleChangeDocs(e, "passport", "expiryYear")
                            }
                            options={getExpiryYearsReactSelect()}
                            value={{
                              label: uploaded.passport
                                ? uploaded.passport.expiryYear
                                : "",
                              value: uploaded.passport
                                ? uploaded.passport.expiryYear
                                : "",
                            }}
                          />
                        </Form.Group>
                      </Form.Row>

                      {!uploaded.passport ||
                      (uploaded.passport &&
                        uploaded.passport.status !== "pending_approval") ? (
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Upload</Form.Label>
                            <FilePond
                              onupdatefiles={fileItems => {
                                if (fileItems.length > 0) {
                                  setUploaded(prevState => {
                                    let files = fileItems.map(
                                      fileItem => fileItem.file
                                    );
                                    if (files.length > 0) {
                                      if (!prevState.passport)
                                        prevState.passport = {};

                                      prevState.passport.documentFront =
                                        files[0].name;
                                      return {
                                        ...prevState,
                                      };
                                    }
                                  });
                                }
                              }}
                              fileRenameFunction={file => {
                                let date = new Date();
                                let time = date.getTime();
                                //prettier-ignore
                                return `${fileID}_${time}_${file.basename}${file.extension}`;
                              }}
                              name={"file"}
                              server={`${API_URL}/upload`}
                            />
                          </Form.Group>
                        </Form.Row>
                      ) : null}

                      {uploaded.passport && uploaded.passport.uploaded ? (
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Preview</Form.Label>
                            <img
                              src={`${API_URL}/public/files/${uploaded.passport.documentFront}`}
                              className={classes.imgPreview}
                              alt=""
                            />
                          </Form.Group>
                        </Form.Row>
                      ) : null}

                      <Form.Row className="d-flex justify-content-between pt-5">
                        <Button
                          variant="secondary"
                          onClick={() => window.location.reload()}
                        >
                          Cancel
                        </Button>

                        {uploaded.passport &&
                        uploaded.passport.status !== "pending_approval" ? (
                          <>
                            {/*{uploaded.passport.uploaded ? (*/}
                            {/*  <Button*/}
                            {/*    style={{*/}
                            {/*      backgroundColor: "var(--color-light-blue2)",*/}
                            {/*    }}*/}
                            {/*    disabled={buttons.disabled}*/}
                            {/*    onClick={() =>*/}
                            {/*      handleSendApprovalDocuments("passport")*/}
                            {/*    }*/}
                            {/*  >*/}
                            {/*    Send for approval*/}
                            {/*  </Button>*/}
                            {/*) : null}*/}

                            <Button
                              variant="primary"
                              type="submit"
                              disabled={buttons.disabled}
                            >
                              Upload
                            </Button>
                          </>
                        ) : null}
                      </Form.Row>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Proof of Address
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <Form
                      onSubmit={e => handleSaveDocuments(e, "proofOfAddress")}
                    >
                      <Form.Text style={{ color: "red" }}>
                        {props.errors.server}
                      </Form.Text>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Country of issuance</Form.Label>
                          <Select
                            name="country"
                            onChange={e =>
                              handleChangeDocs(e, "proofOfAddress", "country")
                            }
                            options={countryListReactSelect()}
                            value={{
                              label: uploaded.proofOfAddress
                                ? countryList[uploaded.proofOfAddress.country]
                                : "",
                              value: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.country
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Passport Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="documentNumber"
                            required
                            placeholder="Enter Passport Number"
                            onChange={e =>
                              handleChangeDocs(e, "proofOfAddress", "")
                            }
                            defaultValue={
                              uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.documentNumber
                                : ""
                            }
                          />
                          <Form.Text style={{ color: "red" }}>
                            {props.errors.documentNumber}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Day</Form.Label>
                          <Select
                            name="expiryDay"
                            onChange={e =>
                              handleChangeDocs(e, "proofOfAddress", "expiryDay")
                            }
                            options={getBirthDaysReactSelect()}
                            value={{
                              label: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.expiryDay
                                : "",
                              value: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.expiryDay
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Month</Form.Label>
                          <Select
                            name="expiryMonth"
                            onChange={e =>
                              handleChangeDocs(
                                e,
                                "proofOfAddress",
                                "expiryMonth"
                              )
                            }
                            options={getBirthMonthsReactSelect()}
                            value={{
                              label: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.expiryMonth
                                : "",
                              value: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.expiryMonth
                                : "",
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Year</Form.Label>
                          <Select
                            name="expiryYear"
                            onChange={e =>
                              handleChangeDocs(
                                e,
                                "proofOfAddress",
                                "expiryYear"
                              )
                            }
                            options={getExpiryYearsReactSelect()}
                            value={{
                              label: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.expiryYear
                                : "",
                              value: uploaded.proofOfAddress
                                ? uploaded.proofOfAddress.expiryYear
                                : "",
                            }}
                          />
                        </Form.Group>
                      </Form.Row>

                      {!uploaded.proofOfAddress ||
                      (uploaded.proofOfAddress &&
                        uploaded.proofOfAddress.status !==
                          "pending_approval") ? (
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Upload</Form.Label>
                            <FilePond
                              onupdatefiles={fileItems => {
                                if (fileItems.length > 0) {
                                  setUploaded(prevState => {
                                    let files = fileItems.map(
                                      fileItem => fileItem.file
                                    );
                                    if (files.length > 0) {
                                      if (!prevState.proofOfAddress)
                                        prevState.proofOfAddress = {};

                                      prevState.proofOfAddress.documentFront =
                                        files[0].name;
                                      return {
                                        ...prevState,
                                      };
                                    }
                                  });
                                }
                              }}
                              fileRenameFunction={file => {
                                let date = new Date();
                                let time = date.getTime();
                                //prettier-ignore
                                return `${fileID}_${time}_${file.basename}${file.extension}`;
                              }}
                              name={"file"}
                              server={`${API_URL}/upload`}
                            />
                          </Form.Group>
                        </Form.Row>
                      ) : null}

                      {uploaded.proofOfAddress &&
                      uploaded.proofOfAddress.uploaded ? (
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Preview</Form.Label>
                            <img
                              src={`${API_URL}/public/files/${uploaded.proofOfAddress.documentFront}`}
                              className={classes.imgPreview}
                              alt=""
                            />
                          </Form.Group>
                        </Form.Row>
                      ) : null}

                      <Form.Row className="d-flex justify-content-between pt-5">
                        <Button
                          variant="secondary"
                          onClick={() => window.location.reload()}
                        >
                          Cancel
                        </Button>

                        {uploaded.proofOfAddress &&
                        uploaded.proofOfAddress.status !==
                          "pending_approval" ? (
                          <>
                            {/*{uploaded.proofOfAddress.uploaded ? (*/}
                            {/*  <Button*/}
                            {/*    style={{*/}
                            {/*      backgroundColor: "var(--color-light-blue2)",*/}
                            {/*    }}*/}
                            {/*    disabled={buttons.disabled}*/}
                            {/*    onClick={() =>*/}
                            {/*      handleSendApprovalDocuments("proofOfAddress")*/}
                            {/*    }*/}
                            {/*  >*/}
                            {/*    Send for approval*/}
                            {/*  </Button>*/}
                            {/*) : null}*/}

                            <Button
                              variant="primary"
                              type="submit"
                              disabled={buttons.disabled}
                            >
                              Upload
                            </Button>
                          </>
                        ) : null}
                      </Form.Row>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            {((uploaded.drivers_licence && uploaded.drivers_licence.uploaded) ||
              (uploaded.passport && uploaded.passport.uploaded)) &&
            uploaded.proofOfAddress &&
            uploaded.proofOfAddress.uploaded ? (
              <Button
                className={[classes.btn, classes.btnSubmit, "roundedBox"].join(
                  " "
                )}
                onClick={() => handleSendApprovalDocuments()}
                // disabled={buttonsDisabled}
              >
                SEND FOR APPROVAL
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

Documents.propTypes = {
  auth: PropTypes.object.isRequired,
  saveDocuments: PropTypes.func.isRequired,
  sendApprovalDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  saveDocuments,
  sendApprovalDocuments,
})(Documents);
