import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/authActions";
import {
  checkClientsProfessionalStatus,
  getProfessionalClientsApplication,
} from "../../../redux/actions/userActions";
import classes from "./ProfessionalClients.module.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const Register = props => {
  const [step, setStep] = useState(1);
  const [profData, setProfData] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    checkClientsProfessionalStatus()
      .then(res => {
        if (res.data.disabledProfessionalClientApplication)
          window.location.href = "/";
      })
      .catch(err => console.log(err));
    getProfessionalClientsApplication()
      .then(res => {
        if (res.data) handleChangeStep(5);
      })
      .catch(err => console.log(err));
  }, []);

  const handleChangeStep = inc => {
    setStep(prevStep => prevStep + inc);
  };

  const handleChange = e => {
    let target = e.target.name;
    let value = e.target.value;

    setProfData(prevState => {
      return {
        ...prevState,
        [target]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    let newUser = {
      step: 1,
      subStep: 2,
      ...setProfData,
      securityQuestion: setProfData.securityQuestion,
    };

    setDisableButton(true);
    props.registerUserWeb(newUser);
  };

  let StepModule;
  switch (step) {
    case 1:
      StepModule = (
        <Step1 handleChange={handleChange} nextStep={handleChangeStep} />
      );
      break;
    case 2:
      StepModule = (
        <Step2
          profData={profData}
          handleChange={handleChange}
          nextStep={handleChangeStep}
        />
      );
      break;
    case 3:
      StepModule = (
        <Step3
          profData={profData}
          handleChange={handleChange}
          nextStep={handleChangeStep}
        />
      );
      break;
    case 4:
      StepModule = (
        <Step4
          profData={profData}
          handleChange={handleChange}
          nextStep={handleChangeStep}
        />
      );
      break;
    case 5:
      StepModule = (
        <Step5
          profData={profData}
          handleChange={handleChange}
          nextStep={handleChangeStep}
          handleSubmit={handleSubmit}
          disableButton={disableButton}
        />
      );
      break;
    case 6:
      StepModule = (
        <Step6 handleChange={handleChange} nextStep={handleChangeStep} />
      );
      break;
    default:
      StepModule = (
        <Step1 handleChange={handleChange} nextStep={handleChangeStep} />
      );
  }

  return (
    <div
      id="professionalClientsPage"
      className={classes.professionalClientsPage}
    >
      {StepModule}
    </div>
  );
};

Register.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Register));
