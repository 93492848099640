import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { checkCreditCardPayment } from "../../../redux/actions/userActions";
import { Col, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { FaCheck } from "react-icons/fa";

const Step = props => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    let returnQS = decodeURIComponent(props.location.search);
    let returnData = queryString.parse(returnQS);

    checkCreditCardPayment({ REF: returnData.REF })
      .then(res => {
        // console.log(res.data);
        if (res.data.success) {
          window.location.href = "/deposit-funds/success";
        } else {
          localStorage.setItem("errorMessage", res.data.error);
          window.location.href = "/deposit-funds/failed";
        }
      })
      .catch(err => console.log(err));
  }, [props.location.search]);

  return (
    <>
      <Row>
        <Col>
          <h1 className="mx-md-4">Deposit Funds</h1>
        </Col>
      </Row>
      <Row className="mx-1 mx-md-4 pt-3 greyBg topBorders">
        {!isMobileOnly ? (
          <Col
            className={["wizardStepWrapper pl-3 pl-md-5 active finished"].join(
              " "
            )}
          >
            <div className="wizardStep">
              <FaCheck className="checkmark" />
            </div>
            <span>
              <div className="wizardStepHeadline">STEP 1</div>
              <div className="wizardStepText">Select your account</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly ? (
          <Col
            className={["wizardStepWrapper pl-3 pl-md-5 active finished"].join(
              " "
            )}
          >
            <div className="wizardStep">2</div>
            <span>
              <div className="wizardStepHeadline">STEP 2</div>
              <div className="wizardStepText">Choose funding method</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly ? (
          <Col
            className={["wizardStepWrapper pl-3 pl-md-5 active finished"].join(
              " "
            )}
          >
            <div className="wizardStep">3</div>
            <span>
              <div className="wizardStepHeadline">STEP 3</div>
              <div className="wizardStepText">Choose the amount</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly ? (
          <Col
            className={["wizardStepWrapper pl-3 pl-md-5 active finished"].join(
              " "
            )}
          >
            {status === "SUCCESS" ? (
              <div className="wizardStep">
                <FaCheck className="checkmark" />
              </div>
            ) : status === "FAILED" ? (
              <div className="wizardStep">X</div>
            ) : (
              <div className="wizardStep">4</div>
            )}

            <span>
              <div className="wizardStepHeadline">STEP 4</div>
              <div className="wizardStepText">Complete payment</div>
            </span>
          </Col>
        ) : null}
      </Row>
      <Row className="mx-1 mx-md-4 greyBg bottomBorders">
        <Col className="py-5">
          <div className="wizardStepsParagraph">Please wait...</div>
        </Col>
      </Row>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
