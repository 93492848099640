import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classes from "./SidebarRegister.module.css";
import logo from "../../assets/images/iQuoto-Logo-White.svg";
import { FaCheck } from "react-icons/fa";

const SidebarRegister = () => {
  // let { location, history } = props;
  const [activeStep, setActiveStep] = useState(1);
  const [activeSubstep, setActiveSubstep] = useState(1);

  useEffect(() => {
    setActiveStep(!localStorage.step ? 1 : parseInt(localStorage.step));
    setActiveSubstep(
      !localStorage.substep ? 1 : parseInt(localStorage.substep)
    );
  }, []);

  const stepCompletedSmallCheck = (
    <span className={classes.circleSmallCompleted}>
      <FaCheck className={classes.checkmarkSmall} />
    </span>
  );
  const circleSmallPlaceholder = (
    <span className={classes.circleSmallPlaceholder} />
  );

  return (
    <div className={classes.sidebarRegister}>
      <object
        data={logo}
        width="172"
        height="65"
        className="sidebarLogo"
        aria-label="iQuoto.com"
      />

      <div className="d-flex pb-2 pl-1 pl-md-5">
        {activeStep > 1 ? (
          <div className={[classes.circle, classes.completedStep].join(" ")}>
            <FaCheck className={classes.checkmark} />
          </div>
        ) : (
          <div
            className={[
              classes.circle,
              activeStep !== 1 ? classes.inactiveStep : classes.activeStep,
            ].join(" ")}
          >
            1
          </div>
        )}
        <div
          className={[
            "pl-2 pl-md-4 text-white",
            classes.textSection,
            activeStep !== 1 ? classes.inactiveStepText : null,
          ].join(" ")}
        >
          <div className={[classes.registerStep].join(" ")}>STEP 1</div>
          <div className={classes.registerStepTitle}>Create Account</div>
          {activeStep === 1 ? (
            <ul
              className={["list-unstyled", classes.registerSubsteps].join(" ")}
            >
              <li
                className={activeSubstep !== 1 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 1
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Create your account
              </li>
              <li
                className={activeSubstep !== 2 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 2
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Security question
              </li>
              <li
                className={activeSubstep !== 3 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 3
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Verify email
              </li>
            </ul>
          ) : null}
        </div>
      </div>

      <div className="d-flex pb-2 pl-1 pl-md-5">
        {activeStep > 2 ? (
          <div className={[classes.circle, classes.completedStep].join(" ")}>
            <FaCheck className={classes.checkmark} />
          </div>
        ) : (
          <div
            className={[
              classes.circle,
              activeStep !== 2 ? classes.inactiveStep : classes.activeStep,
            ].join(" ")}
          >
            2
          </div>
        )}
        <div
          className={[
            "pl-2 pl-md-4 text-white",
            classes.textSection,
            activeStep !== 2 ? classes.inactiveStepText : null,
          ].join(" ")}
        >
          <div className={[classes.registerStep].join(" ")}>STEP 2</div>
          <div className={classes.registerStepTitle}>Personal Details</div>
          {activeStep === 2 ? (
            <ul
              className={["list-unstyled", classes.registerSubsteps].join(" ")}
            >
              <li
                className={activeSubstep !== 1 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 1
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Contact details
              </li>
              <li
                className={activeSubstep !== 2 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 2
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Date of birth
              </li>
              <li
                className={
                  activeSubstep !== 3 && activeSubstep !== 4
                    ? classes.inactiveSubstep
                    : null
                }
              >
                {activeSubstep > 4
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Residential address
              </li>
              <li
                className={activeSubstep !== 5 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 5
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Nationality
              </li>
              <li
                className={
                  activeSubstep !== 6 &&
                  activeSubstep !== 7 &&
                  activeSubstep !== 8
                    ? classes.inactiveSubstep
                    : null
                }
              >
                {activeSubstep > 8
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Employment information
              </li>
              <li
                className={activeSubstep !== 9 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 9
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Financial information
              </li>
            </ul>
          ) : null}
        </div>
      </div>

      <div className="d-flex pb-2 pl-1 pl-md-5">
        {activeStep > 3 ? (
          <div className={[classes.circle, classes.completedStep].join(" ")}>
            <FaCheck className={classes.checkmark} />
          </div>
        ) : (
          <div
            className={[
              classes.circle,
              activeStep !== 3 ? classes.inactiveStep : classes.activeStep,
            ].join(" ")}
          >
            3
          </div>
        )}
        <div
          className={[
            "pl-2 pl-md-4 text-white",
            classes.textSection,
            activeStep !== 3 ? classes.inactiveStepText : null,
          ].join(" ")}
        >
          <div className={[classes.registerStep].join(" ")}>STEP 3</div>
          <div className={classes.registerStepTitle}>Experience</div>
          {activeStep === 3 ? (
            <ul
              className={["list-unstyled", classes.registerSubsteps].join(" ")}
            >
              <li
                className={activeSubstep !== 1 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 1
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Trading experience
              </li>
              {/*<li*/}
              {/*  className={activeSubstep !== 2 ? classes.inactiveSubstep : null}*/}
              {/*>*/}
              {/*  Trading knowledge*/}
              {/*</li>*/}
            </ul>
          ) : null}
        </div>
      </div>

      <div className="d-flex pb-2 pl-1 pl-md-5">
        {activeStep > 4 ? (
          <div className={[classes.circle, classes.completedStep].join(" ")}>
            <FaCheck className={classes.checkmark} />
          </div>
        ) : (
          <div
            className={[
              classes.circle,
              activeStep !== 4 ? classes.inactiveStep : classes.activeStep,
            ].join(" ")}
          >
            4
          </div>
        )}
        <div
          className={[
            "pl-2 pl-md-4 text-white",
            classes.textSection,
            activeStep !== 4 ? classes.inactiveStepText : null,
          ].join(" ")}
        >
          <div className={[classes.registerStep].join(" ")}>STEP 4</div>
          <div className={classes.registerStepTitle}>Account Details</div>
          {activeStep === 4 ? (
            <ul
              className={["list-unstyled", classes.registerSubsteps].join(" ")}
            >
              <li
                className={activeSubstep !== 1 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 1
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Base currency
              </li>
              <li
                className={activeSubstep !== 2 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 2
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Platform
              </li>
              <li
                className={activeSubstep !== 3 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 3
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Account type
              </li>
            </ul>
          ) : null}
        </div>
      </div>

      <div className="d-flex pb-2 pl-1 pl-md-5">
        {activeStep > 5 ? (
          <div className={[classes.circle, classes.completedStep].join(" ")}>
            <FaCheck className={classes.checkmark} />
          </div>
        ) : (
          <div
            className={[
              classes.circle,
              activeStep !== 5 ? classes.inactiveStep : classes.activeStep,
            ].join(" ")}
          >
            5
          </div>
        )}
        <div
          className={[
            "pl-2 pl-md-4 text-white",
            classes.textSection,
            activeStep !== 5 ? classes.inactiveStepText : null,
          ].join(" ")}
        >
          <div className={[classes.registerStep].join(" ")}>STEP 5</div>
          <div className={classes.registerStepTitle}>Legal</div>
          {activeStep === 5 ? (
            <ul
              className={["list-unstyled", classes.registerSubsteps].join(" ")}
            >
              <li
                className={activeSubstep !== 1 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 1
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Risk disclosure
              </li>
              <li
                className={activeSubstep !== 2 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 2
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Declaration
              </li>
              <li
                className={activeSubstep !== 3 ? classes.inactiveSubstep : null}
              >
                {activeSubstep > 3
                  ? stepCompletedSmallCheck
                  : circleSmallPlaceholder}
                Client Assets Declaration
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SidebarRegister);
