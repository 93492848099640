import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab, Button, Card } from "react-bootstrap";
import classes from "./Platforms.module.css";
import platformWin from "../../../assets/images/platforms-win.svg";
import platformIos from "../../../assets/images/platforms-ios.svg";
import platformAndroid from "../../../assets/images/platforms-android.svg";
import platformMac from "../../../assets/images/platforms-mac.svg";

const Platforms = ({ history, ...props }) => {
  const [key, setKey] = useState("mt4");

  useEffect(() => {
    setKey(props.match.params.target);
  }, [props.match.params.target]);

  const handlePlatformChange = target => {
    setKey(target);
    history.push(`/platforms/${target}`);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">Platforms</h1>
          </Col>
        </Row>
        <Row className="mx-md-4">
          <Col className="px-0">
            <Tabs
              activeKey={key}
              onSelect={k => handlePlatformChange(k)}
              transition={false}
              id="downloads-tabs"
            >
              <Tab eventKey="mt4" title="METATRADER 4" className="p-4">
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 4 for Windows
                      </div>

                      <img
                        src={platformWin}
                        className="d-block mx-auto my-3"
                        alt="Platform Windows"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/web/14011/mt4/iquoto4setup.exe"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 4 for iOS
                      </div>

                      <img
                        src={platformIos}
                        className="d-block mx-auto my-3"
                        alt="Platform iOS"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/mobile/mt4/ios?server=iQuoto-Demo,iQuoto-Live"
                        target="_blank"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 4 for Android
                      </div>

                      <img
                        src={platformAndroid}
                        className="d-block mx-auto my-3"
                        alt="Platform Android"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/mobile/mt4/android?server=iQuoto-Demo,iQuoto-Live"
                        target="_blank"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 4 for Mac
                      </div>

                      <img
                        src={platformMac}
                        className="d-block mx-auto my-3"
                        alt="Platform Mac"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg?utm_source=www.metatrader4.com&utm_campaign=download.mt4.macos"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>

              <Tab eventKey="mt5" title="METATRADER 5" className="p-4">
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 5 for Windows
                      </div>

                      <img
                        src={platformWin}
                        className="d-block mx-auto my-3"
                        alt="Platform Windows"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/web/iquoto.ltd/mt5/iquoto5setup.exe"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 5 for iOS
                      </div>

                      <img
                        src={platformIos}
                        className="d-block mx-auto my-3"
                        alt="Platform iOS"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/mobile/mt5/ios?server=iQuoto-Demo,iQuoto-Live"
                        target="_blank"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 5 for Android
                      </div>

                      <img
                        src={platformAndroid}
                        className="d-block mx-auto my-3"
                        alt="Platform Android"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/mobile/mt5/android?server=iQuoto-Demo,iQuoto-Live"
                        target="_blank"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="cardWithLeftBorder mx-1 mx-md-3 my-2">
                  <div className="cardBorder red" />
                  <Card
                    className={["customCardsStyle", classes.PlatformCard].join(
                      " "
                    )}
                  >
                    <Card.Body>
                      <div className={classes.platformTitle}>
                        MetaTrader 5 for Mac
                      </div>

                      <img
                        src={platformMac}
                        className="d-block mx-auto my-3"
                        alt="Platform Mac"
                      />

                      <Button
                        className="cardButton platformDownload d-block mx-auto"
                        style={{
                          backgroundColor: "var(--color-dark-blue1)",
                        }}
                        variant="link"
                        href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=www.metatrader5.com&utm_campaign=download"
                      >
                        DOWNLOAD
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(Platforms);
