import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { acceptDeclarations } from "../../../redux/actions/userActions";
import classes from "../../../containers/Auth/Register/RegisterNew.module.css";
import { NotificationManager } from "react-notifications";

const AcceptDeclarations = props => {
  const handleAcceptDeclarations = event => {
    event.preventDefault();
    event.stopPropagation();

    const acceptDeclaration =
      document.getElementById("acceptDeclaration").checked;

    if (
      acceptDeclaration ||
      (!acceptDeclaration &&
        window.confirm(
          "I Agree to all the above declarations and the GCS Disclosure."
        ))
    ) {
      acceptDeclarations()
        .then(res => {
          if (res.data.success) {
            localStorage.setItem("userId", props.auth.user.id.toString());
            window.location.reload();
          } else NotificationManager.error(res.data.error, "", 3000);
        })
        .catch(err => console.log(err));
    }
  };

  return props.auth.isAuthenticated &&
    props.auth.user.status === "approved" &&
    window.location.pathname !== "/ideal" &&
    !(
      props.auth.user.acceptedDeclaration ||
      (!props.auth.user.acceptedDeclaration &&
        localStorage.userId &&
        localStorage.userId === props.auth.user.id)
    ) ? (
    <Modal show={true} size="lg">
      <Modal.Header>
        <Modal.Title>Client Assets Declarations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => handleAcceptDeclarations(e)}>
          <Form.Row>
            <Form.Text className="pb-3">
              1. you have read and understood the GCEN Terms and Conditions
              (available at https://www.gcpartners.co/terms-of-service/) and the
              Fees and Charges Annex which we have provided to you separately
              (together, the "GCEN Customer Agreement");
            </Form.Text>
          </Form.Row>

          <Form.Row>
            <Form.Text className="pb-3">
              2. you agree to enter into an agreement with Global Currency
              Exchange Network Limited (“GCEN”) on the terms of the GCEN
              Customer Agreement pursuant to which GCEN will provide to you
              money remittance and foreign exchange services;{" "}
            </Form.Text>
          </Form.Row>

          <Form.Row>
            <Form.Text className="pb-3">
              3. you agree to GCEN opening in its books an account in your name,
              subject to and in accordance with the GCEN Customer Agreement;
            </Form.Text>
          </Form.Row>

          <Form.Row>
            <Form.Text className="pb-3">
              4. you acknowledge and agree that:
              <br />
              a. GCEN reserves the right to refuse to open any account for you
              on any grounds, as permitted by the GCEN Customer Agreement; and
              <br />
              b. you understand that GCEN does not provide any services to you
              other than those set out in the GCEN Customer Agreement, and that
              GCEN will not have any responsibility or liability to you for your
              decision to invest, or the consequences of any investment by you,
              or the acts or omissions of any issuer of investments in which you
              invest or of any third party which provides services to such
              issuer.
            </Form.Text>
          </Form.Row>

          <Form.Row>
            <Form.Text className="pb-3">
              You can find GCEN's website{" "}
              <Button
                variant="link"
                className="orangeLink"
                href="https://www.gcpartners.co/"
                target="_blank"
              >
                here
              </Button>
              , the GCEN Terms and Conditions{" "}
              <Button
                variant="link"
                className="orangeLink"
                href="https://www.gcpartners.co/terms-of-service/"
                target="_blank"
              >
                here
              </Button>
              , and GCEN's Privacy Notice can be found{" "}
              <Button
                variant="link"
                className="orangeLink"
                href="https://www.gcpartners.co/privacy-notice/"
                target="_blank"
              >
                https://www.gcpartners.co/privacy-notice/
              </Button>
              .
            </Form.Text>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-4" style={{ maxWidth: "28px" }}>
              <Form.Check
                custom
                inline
                label=""
                type="checkbox"
                id="acceptDeclaration"
              />
            </Form.Group>
            <Form.Group as={Col} className="mt-4">
              <label className={classes.smallLabel} htmlFor="acceptDeclaration">
                I Agree to all the above declarations and the{" "}
                <Button
                  variant="link"
                  className="orangeLink"
                  href="https://www.gcpartners.co/gcs-disclaimer/"
                  target="_blank"
                >
                  GCS Disclosure
                </Button>
                .
              </label>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          type="submit"
          className="blueButton mt-0"
          style={{
            fontWeight: "normal",
          }}
          onClick={handleAcceptDeclarations}
        >
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

AcceptDeclarations.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AcceptDeclarations);
