import React from "react";
import { Col, Row, Button, Card, Form } from "react-bootstrap";
import classes from "./Withdraw.module.css";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const handleUpdateAmount = e => {
    props.updateAmount(e.target.value);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="7">
        <div className="wizardStepsParagraph">
          Please enter the amount that you would like to withdraw:
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
          <div className="fundingInputValuesWrapper">
            <Form.Control
              type="number"
              id="amount"
              name="amount"
              className="fundingInputValue h-100"
              placeholder="AMOUNT"
              onChange={e => handleUpdateAmount(e)}
              defaultValue={props.withdrawAmount || ""}
            />
            <span className="cardFlag cardCurrencyFlag">
              {props.selectedAccount.baseCurrency}
            </span>
          </div>

          <div className="wizardFundAmount">
            <Button
              className="cardButton float-left mt-3 ml-1"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={() => props.nextStep("select_payment_method")}
            >
              BACK
            </Button>
            <Button
              className="cardButton float-right mt-3"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={props.selectAmount}
            >
              NEXT
            </Button>
          </div>
        </div>
      </Col>
      <Col md="5">
        <Card
          className={[
            "customCardsStyle customCardsStyleMediumFont",
            classes.PaymentMethodCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
              {props.selectedAccount.number}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">WITHDRAWAL METHOD</div>
              {props.paymentMethod.method}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">
                BALANCE AVAILABLE TO WITHDRAW
              </div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccount.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">WITHDRAWAL AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.withdrawAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">WITHDRAWAL FEE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {props.paymentMethod.fees}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Step;
