import React, { useEffect } from "react";
import { getUser } from "../../../redux/actions/authActions";
import { sendApprovalDocuments } from "../../../redux/actions/userActions";
import { Col, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { NotificationManager } from "react-notifications";

const Success = () => {
  useEffect(() => {
    getUser()
      .then(res => {
        if (!res.data.user.gcenApiDocsSubmitted) {
          sendApprovalDocuments()
            .then(res => {
              console.log(res.data);
              console.log(
                "You have successfully sent your documents for approval."
              );
            })
            .catch(err => {
              NotificationManager.error(
                "Something went wrong on our side. Please, contact support and we'll look into it.",
                "",
                5000
              );
              console.error(err);
            });
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Row className="mx-4 pt-5 pb-3 greyBg roundedBox wizardComplete">
      <Col>
        <div className="circle success checkmarkAnimation">
          <FaCheck className="checkmark" />
        </div>

        <p className="messageTitle success">DOCUMENTS SUCCESSFULLY UPLOADED!</p>

        <p className="messageText mx-auto">
          Thank you for complete your account verification. Your account is now
          pending review from our compliance department.
          <br />
          Please note that accounts can take up to 48 hours to be processed.
          <br />
          If you have any questions or need assistance, don’t hesitate to
          contact us at clientservices@iquoto.com.
        </p>
      </Col>
    </Row>
  );
};

export default Success;
